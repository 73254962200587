import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DWDeletePayoutTokens, PayoutTokens, PayoutTokensResponse } from 'src/app/shared/models/payouttokens.model';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { PayoutTokensService } from 'src/app/shared/services/api/get-payout-tokens.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { TransferDeleteComponent } from '../transfer-delete/transfer-delete.component';
import { DWSetAutoTransferResponse } from 'src/app/shared/models/AutoTransfer.model';
import { TransferPopUpComponent } from '../transfer-pop-up/transfer-pop-up.component';
import { DeactivateTransferPopUpComponent } from '../deactivate-transfer-pop-up/deactivate-transfer-pop-up.component';
import { AutoTransferService } from 'src/app/shared/services/api/auto-transfer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auto-transfer-flyout',
  templateUrl: './auto-transfer-flyout.component.html',
  styleUrls: ['./auto-transfer-flyout.component.scss']
})
export class AutoTransferFlyoutComponent {
  [x: string]: any;

  isVisible = false;
  payouttokens: any;
  paymenttokens: any;
  instantaccounts: any;
  zelleaccounts: any;
  selectedtoken:any;
  WalletID: any;
  dwdpatsession: any;
  sessionService: any;
  payoutaccounts: any;
  nopayments: boolean = true;
  walletDetails:any;
  loading:boolean=true;
  autoTransferToggle: boolean = false;
  toggleText: string = 'Off'; 
  transferType:string='';
  defaultToken:string='';

  isinstant:boolean=false;
  NoAccountsAT:boolean;
  ATValidationMsg:string='';

  constructor(public sessionservice:GetGuidService,public payouttoken:PayoutTokensService, public dialogRef: MatDialogRef<AutoTransferFlyoutComponent>, public dialog:MatDialog,public autotransferservice
    :AutoTransferService, public route:Router,
    @Inject(MAT_DIALOG_DATA) public data: any,public userService:UserService){}
  openFlyout() {
    this.dialogRef.close('accept');
    this.isVisible = true;
  }

  closeFlyout() {
    this.dialogRef.close('back');
    this.isVisible = false;
  }

  ngAfterContentChecked(){
    
    if(document.getElementById("0")!=null && document.getElementById("1")!=null)
    {
      
    if(this.isinstant){
    var ele=document.getElementById("0")as HTMLInputElement;
    ele.checked=true;

    }
    else{
      
      var ele=document.getElementById("1")as HTMLInputElement;
    ele.checked=true;

    }
  }
    if(this.defaultToken !=undefined && this.defaultToken !='' && document.getElementById(this.selectedtoken)!=null){
    
      /* if(this.isinstant?"rtp":"nonrtp"==this.transferType.toLowerCase()){
  document.getElementById(this.selectedtoken).setAttribute("checked","true");
      } */
  }
  }

  reloadpaymethods(transferType:any){
    this.isinstant=false;
    if(transferType.toLowerCase()=='instant'){
    this.isinstant=true;
    }
    
    this.loading=true;
    this.loadpayouttokens();
  }

  ngOnInit()
  {
    
    this.loading=true;
    const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');
  if (autoTransferData) {
    this.autoTransferToggle = autoTransferData.isAutoTransfer;
    this.transferType = autoTransferData.transferType;
    this.defaultToken = autoTransferData.defaultToken;
  }
  if(this.transferType.toLowerCase()=='rtp'){
    this.isinstant=true;
  }
  else{
    this.isinstant=false;
  }
  if(this.defaultToken !=undefined && this.defaultToken !=''){
this.selectedtoken=this.defaultToken;

//document.getElementById(this.selectedtoken).setAttribute("checked","true");

  }
    this.walletDetails = this.userService.getWalletDetails();

    this.loadpayouttokens();
  }
  addPaymentMethod():void{
this.loading=true;

    if(sessionStorage.length>0)
{
this.WalletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
}    
  
 
this.dwdpatsession=new DWDPATSessionModel()
this.dwdpatsession.action='addpayoutpayment';
this.dwdpatsession.callBackURL=`${ApiEndpoints.startWalletSessionCallBack}/my-wallet/my-cash/transfer-balance` ;
this.dwdpatsession.country='US';
this.dwdpatsession.createdByUserId='appdw';
this.dwdpatsession.locale='en';
this.dwdpatsession.navigatingSystem='dw';
this.dwdpatsession.paymentDirection='payout';
this.dwdpatsession.walletID=this.WalletID;
this.dwdpatsession.cashbalance=this.walletDetails?.cash?.totalCashBalance.toString(),
this.dwdpatsession.rewardsBalance=this.walletDetails?.rewards?.totalRewardBalance.toString(),

this.sessionservice.createdwdpatwalletsession(this.dwdpatsession);
this.loading=false;
  }

  deleteToken(token: string) {
    let dialogRef=this.dialog.open(TransferDeleteComponent);
      dialogRef.afterClosed().subscribe(
        result => {
          
          if (result=='accept') {
            this.loading=true;
            let dwDeletePayRequest: DWDeletePayoutTokens = new DWDeletePayoutTokens();
            if(sessionStorage.length>0)
            {
              dwDeletePayRequest.walletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
              dwDeletePayRequest.walletRole=JSON.parse(sessionStorage.getItem("WalletRole") || '{}');
              dwDeletePayRequest.payeeId=token;
            }
            
            
            this.payouttoken.deletepaymentmethod(dwDeletePayRequest).subscribe({
              next: (data: PayoutTokensResponse) => {
                console.log("delete token " + data.success);
                this.loadpayouttokens();
                window.location.reload();
        
              }
            });
            console.log(token + 'deleted');
        
            //this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
          }
          
        }
      );

  }

  loadpayouttokens():void{
    let payouttokens:PayoutTokens= new PayoutTokens() ;
    payouttokens.transferType='NonRTP';
    if(this.isinstant){
      payouttokens.transferType='RTP';
    }
    
    payouttokens.walletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    payouttokens.walletRole=JSON.parse(sessionStorage.getItem("WalletRole") || '{}');
   
   
       let listoftokens= this.payouttoken.fetchpayouttokens(payouttokens).subscribe({
         next: (data:PayoutTokensResponse) => {
           console.log("is payouttokens call successfull "+ data.success);
           if(data.success==true)
           this.nopayments=false;
           console.log("payintokens "+ data.payoutTokens);
           this.paymenttokens= data.payoutTokens;
           this.paymenttokens.forEach((token:any) => {
            if(token.zelleIndicator=='T')
            {
          token.cardImg=this.payouttoken.getcardImage('zelle');
          
            }
            else{
              token.counterpartyPayOrg=token.counterpartyPayOrg.replace('NA','');
             token.cardImg= this.payouttoken.getcardImage(token.counterpartyPayOrg);
             if(token.counterPartyAccountNumber!=null && token.counterPartyAccountNumber!=undefined)
             {
              let accnumber=token.counterPartyAccountNumber;
              token.counterPartyAccountNumber=accnumber.substring(accnumber.length-4,accnumber.length+1);
             }
             else{
              token.counterPartyAccountNumber='0000';
             }
            }
            /*  let expiration=token.ccexpiration;
             token.ccexpiration=expiration.slice(4,6)+"/"+ expiration.slice(2,4) */
           });
           
           this.instantaccounts=this.paymenttokens.filter((x:any)=>x.achIndicator=='T');
           this.zelleaccounts=this.paymenttokens.filter((x:any)=>x.achIndicator=='F');
           this.payoutaccounts=this.paymenttokens;
           if(this.payoutaccounts.length>0)
           {
        this.payoutaccounts[this.payoutaccounts.length-1].isLastOption=true;
           }
        this.loading=false; 
        if(this.payoutaccounts.length<1)
        {
        this.NoAccountsAT=true;
        this.ATValidationMsg="Please add a transfer account before enabling Auto-Transfer.";
        }
        else{
          this.NoAccountsAT=false;
          const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');
  if (autoTransferData) {
   if(autoTransferData.isAutoTransfer){
    if(autoTransferData.transferType.toLowerCase()==this.isinstant?"rtp":"nonrtp")
    {
      document.getElementById(this.selectedtoken).setAttribute("checked","true");
    }
    else{document.getElementById(this.selectedtoken).setAttribute("checked","false");}
   }
  }
        }
         }
        });
   
   
  }
  enableBtn() {
    if(this.isinstant?"rtp":"nonrtp"==this.transferType.toLowerCase()){
      document.getElementById(this.selectedtoken).setAttribute("checked","true");
          }
    this.errorMessage = '';
  }


  // onToggleChange(event: any): void {
  //   let transferType=this.isinstant ? 'RTP' : 'NonRTP'
  //   if (this.autoTransferToggle) {
  //     if (!this.selectedtoken) {
  //       this.autoTransferToggle = false;
  //       this.errorMessage = 'Please select an account to set Auto Transfer.';
  //           }
            
  //         }
  // else{
  //     const dialogRef = this.dialog.open(TransferPopUpComponent, {
  //       width: '832px',
  //       data: {      
  //         transferType: this.lastTransferType, // Pass transfer type
  //         defaultToken: this.selectedtoken,   // Pass selected token
  //       } // Pass any data to the modal if needed
  //     });
  
  //     dialogRef.afterClosed().subscribe((result) => {
  //       if (result) {
  //         // User confirmed "Yes"
  //         const atransfer = {
  //           defaultToken: this.selectedtoken,
  //           isAutoTransfer: true,
  //           transferType: transferType
  //         };
  
  //         sessionStorage.setItem('AutoTransfer', JSON.stringify(atransfer));
  //         this.autotransferservice.setautotransfer(true,this.selectedtoken,atransfer.transferType).subscribe({next:(data:DWSetAutoTransferResponse)  => {
  //         console.log(JSON.stringify(data));
  //         }});
  //         this.route.navigate(['my-wallet', 'my-cash', 'auto-transfer']);
  //       } else {
  //         // User canceled
  //           this.autotransferservice.setautotransfer(false,this.selectedtoken,transferType).subscribe({next:(data:DWSetAutoTransferResponse)  => {
  //             console.log(JSON.stringify(data));
  //             }});
  //         this.autoTransferToggle = false;
  //       }
  //     });
  //   }}
  
  onToggleChange(event: any): void {
    let transferType=this.isinstant ? 'RTP' : 'NonRTP'
    if (this.autoTransferToggle) {
      document.getElementById('auto-content').classList.remove('disable-content');
            
          }
  else{
    document.getElementById('auto-content').classList.add('disable-content');

    }
  }
  
  UpdateAutoTransfer(): void {
    let transferType=this.isinstant ? 'RTP' : 'NonRTP'
    const payeeID = this.selectedtoken;

  
    this.autotransferservice.setautotransfer(this.autoTransferToggle, payeeID, transferType).subscribe({
      next: (response) => { 
        
        console.log('Auto Transfer updated successfully:', response);
        sessionStorage.removeItem("AutoTransfer");
        sessionStorage.setItem(
          'AutoTransfer',
          JSON.stringify({
            isAutoTransfer: this.autoTransferToggle,
            transferType: transferType,
            defaultToken: payeeID,
          })
        );
        if(!this.autoTransferToggle)
        {
        this.closeFlyout();
        this.route.navigate(['my-wallet', 'my-cash', 'transfer-balance']);
        }
        else{
        window.location.reload(); // Update toggle state based on API response
      }},
      error: (error) => {
        console.error('Error updating Auto Transfer:', error);
        window.location.reload();// Revert toggle state in case of an error
      },
    });
  }
  

}
