import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import  jsonData from '../../../../assets/json/transactions.json';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { TransactionHistoryService } from 'src/app/shared/services/api/transaction-history.service';
import { WalletDetails } from 'src/app/shared/models/WalletDetails.interface';
import { InvoicePayment } from 'src/app/shared/models/transaction-history.interface';
import { TransactionHistory } from 'src/app/shared/models/transaction-history.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { RewardCommon } from 'src/app/shared/models/RewardCommon';


@Component({
  selector: 'app-my-wallet-details',
  templateUrl: './my-wallet-details.component.html',
  styleUrls: ['./my-wallet-details.component.scss']
})

export class MyWalletDetailsComponent implements OnInit {

  dataSource = new MatTableDataSource<RewardCommon>([]);
 
  displayedColumns: string[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selection = new SelectionModel<RewardCommon>(true, []);
  searchValue: string = '';

  rewardType: string = 'earned';
  transactionType: string = 'all';
  walletID: string = '';
  walletRole: string = '';

  autoPay: number = 0;
  cashEligible = false;
  rewardEligible = false;
  dataLoaded = false;


  constructor(
    public route:Router,
    private walletSummaryService: WalletSummaryService,
    private transactionHistoryService: TransactionHistoryService
    ){
      // If user clicks 'see all' on home page for 'wallet spending' table we show fetch data from the redeemed table for them.
      let type = sessionStorage.getItem('navigationSource');
      if (type == 'redeemed') {
        this.rewardType = 'redeemed';
      }
      sessionStorage.removeItem('navigationSource');
    }
  
  transactions = jsonData; 
  walletSummary: WalletSummary | null = null; 

  selectAll(event: Event) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    
    this.transactions.forEach(transaction => {
      transaction.selected = isChecked;
    });
  }
  
  redeemPay(){
    this.route.navigate(['my-wallet', 'all-rewards', 'redeem']);
  }

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
        this.walletID = summary.wallet.walletID;
        this.walletRole = 'admin'; // Replace 'yourRole' with the actual logic to determine walletRole
        
        const isDWAutoPayValue = Number(summary.wallet.isDWAutoPay);
        this.autoPay = isDWAutoPayValue;
    
        this.walletSummary = summary;
        // this.rewardType = 'earned';
        this.dataLoaded = true;
        this.fetchData();
      }
    });

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dateString = new Date(data.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return dateString.toLowerCase().includes(filter.toLowerCase());
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearch(searchValue: string) {
    const formattedSearchValue = searchValue.trim().toLowerCase();
    this.dataSource.filter = formattedSearchValue;
   // this.dataSource.filter = searchValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchRewards(): void {
    console.log('Fetching wallet ...');
    console.log('walletID, walletRole:', this.walletID, this.walletRole);

    this.transactionHistoryService.fetchTransactionHistory(this.walletID, this.walletRole, 'all').subscribe(
      (data) => {  
        if (this.rewardType === 'earned') {   
          this.dataSource = new MatTableDataSource(this.transformData(data.walletTransactionsList.rewards.rewardsEarn, true));
          this.displayedColumns = ['select', 'earnDate', 'pointsEarned', 'earnCategory', 'earnIdentifier']; 
        } else if (this.rewardType === 'redeemed') {  
          this.dataSource = new MatTableDataSource(this.transformData(data.walletTransactionsList.rewards.rewardsSpends, false));
          this.displayedColumns = ['select', 'spendDate', 'pointSpend', 'spendCategory', 'spendIdentifierDescription','status'];  
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.error('Error fetching rewards:', error);
      }
    );
  }



  fetchData(): void {
    console.log('Fetching wallet data...');
    console.log('walletID, walletRole:', this.walletID, this.walletRole);
  
    this.transactionHistoryService.fetchTransactionHistory(this.walletID, this.walletRole).subscribe(
      (data) => {
        this.processData(data.walletTransactionsList);
        console.log("Wallet Data: ", data);
      },
      (error) => {
        console.error('Error fetching wallet data:', error);
      }
    );
  }
  
 processData(walletTransactionsList: any): void {
    // Extracting relevant data from the walletTransactionsList
    const rewardsEarn = walletTransactionsList.rewards.rewardsEarn || [];
    const rewardsSpend = walletTransactionsList.rewards.rewardsSpends || [];
    const cashEarn = walletTransactionsList.cash.cashEarn || [];
    const cashSpend = walletTransactionsList.cash.cashSpend || [];

    let dataToDisplay: any[] = [];
    this.displayedColumns = []; // Reset displayed columns

    // Determine which data to display based on the selected reward type
    if (this.rewardType === 'earned') {
        dataToDisplay = [...rewardsEarn, ...cashEarn];
        this.displayedColumns = ['select', 'date', 'amount', 'category', 'detail', 'identifier'];
    } else if (this.rewardType === 'redeemed') {
        dataToDisplay = [...rewardsSpend, ...cashSpend];
        this.displayedColumns = ['select', 'date', 'amount', 'category', 'detail', 'identifier', 'account','status'];
    }

    // Sort the combined data by date in descending order
    dataToDisplay.sort((a, b) => {
        const dateA = new Date(this.rewardType === 'earned' ? a.earnDate : a.spendDate);
        const dateB = new Date(this.rewardType === 'earned' ? b.earnDate : b.spendDate);
        return dateB.getTime() - dateA.getTime(); // Descending order
    });

    // Transform the data and set it to the dataSource
    this.dataSource = new MatTableDataSource(this.transformData(dataToDisplay, this.rewardType === 'earned'));
    
    // Set paginator and sort
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
  

  transformData(data: any[], isEarn: boolean): any[] {
    return data.map((item) => ({
      date: isEarn ? item.earnDate : item.spendDate,
      amount: isEarn ? (item.pointsEarned || item.cashEarned) : (item.pointSpend || item.spendAmount),
      isCash: isEarn ? !!item.cashEarned : !!item.spendAmount,
      category: isEarn ? item.earnCategory : item.spendCategory,
      detail: isEarn
      ? this.getEarnDetail(item.earnCategoryDescription, item.modeOfTransfer)
      : this.getSpendDetail(item.spendCategoryDescription, item.modeOfTransfer), // Pass modeOfTransfer

      identifier: isEarn 
      ? (item.earnIdentifierDescription === 'InvoiceID' 
          ? `INV. ${item.earnIdentifier}` // Display earnIdentifier with INV prefix if earnIdentifierDescription is 'InvoiceID'
          : item.earnIdentifierDescription === 'ClaimNumber' 
            ? item.earnIdentifier // Display earnIdentifier without prefix if earnIdentifierDescription is 'ClaimNumber'
            : 'N/A') // Display 'N/A' if earnIdentifierDescription is neither 'InvoiceID' nor 'ClaimNumber'
      : (item.spendIdentifierDescription === 'InvoiceID' || item.spendCategory === 'CashSpendInvoice')
          ? `INV. ${item.spendIdentifier}` // Add INV prefix for spendIdentifier if applicable
          : item.spendIdentifier, // Display spendIdentifier for spendings

          
      fundsType: isEarn ? (item.cashEarned ? 'Cash' : 'Rewards') : (item.spendAmount ? 'Cash' : 'Rewards'), 
      
      accountBankName: !isEarn && item.spendIdentifierDescription === 'PayableReferenceNumber'
      ? (item.spendAccountRedactedDescription === 'ZELLE' 
          ? `Zelle -` 
          : this.formatBankName(item.spendAccountBankName)) 
      : 'N/A',


      accountAlias: !isEarn && item.spendIdentifierDescription === 'PayableReferenceNumber' ? item.spendAccountAlias : 'N/A',   
      identifierDescription: isEarn ? null : item.spendIdentifierDescription, // Map spendIdentifierDescription
      selected: false,
      status:item.status==''|| item.status==undefined?'N/A':item.status
    }));
  }

  private getSpendDetail(spendCategoryDescription: string, modeOfTransfer: string): string {
    let detail = '';
    switch (spendCategoryDescription) {
      case 'CashSpendTransfer':
        detail = 'Cash Transfer';
        break;
        case 'CashSpendInvoice':
          detail = 'Invoice Redemption';
          break;  
        case 'RewardsSpendInvoice':
            detail = 'Invoice Redemption';
            break;  
            case 'RewardsSpendInvoicePaid':
              detail = 'Invoice Redemption';
              break;      
        case 'CashEarnClaimsPaid':
        detail = 'Claims';
        break;
      default:
        detail = spendCategoryDescription; // Use the original description if no match
    }
  
    if (modeOfTransfer === 'RTP') {
      detail += ' <i>- Instant</i>'; // Append "- Instant" in italics
    }
  
    return detail;
  }

  private getEarnDetail(earnCategoryDescription: string, modeOfTransfer: string): string {
    let detail = '';
    switch (earnCategoryDescription) {
      case 'RewardsEarnProfileValidation':
        detail = 'Online Portal Profile Validatoin';
        break;
      case 'CashEarnClaimsPaid':
        detail = 'Claims';
        break;
      default:
        detail = earnCategoryDescription; // Use the original description if no match
    }
  
    if (modeOfTransfer === 'RTP') {
      detail += ' <i>- Instant</i>'; // Append "- Instant" in italics
    }
  
    return detail;
  }
  
  
  private formatBankName(bankName: string): string {
    if (bankName.startsWith('JPMORGAN CHASE BANK, NA')) {
      return 'CHASE BANK, NA';
    }
    return bankName;
  }
  

  onRewardTypeChange(rewardType: string): void {
    const walletID = sessionStorage.getItem('WalletID');
    const walletRole = sessionStorage.getItem('WalletRole');

    this.rewardType = rewardType;
    this.displayedColumns = this.getDisplayedColumns();
   
    if (walletID && walletRole) {
      this.fetchRewards();
    } else {
      console.error('Wallet ID or Wallet Role is missing');
    }
    console.log('Reward Type changed to:', rewardType);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: RewardCommon): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.date} ${row.amount}`;
  }


  getDisplayedColumns(): string[] {
    if (this.rewardType === 'earned') {
      return ['select', 'date', 'amount', 'category', 'detail'];
    } else if (this.rewardType === 'redeemed') {
      return ['select', 'date', 'amount', 'category', 'identifier', 'account','status'];
    } else {
      // Default or error case
      return [];
    }
  }
  

}
