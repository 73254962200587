import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm, FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from 'src/app/shared/components/modal-content/modal-content.component';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { GetPayinTokensService } from 'src/app/shared/services/api/get-payin-tokens.service';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { DWDefaultPaymentRequest } from 'src/app/shared/models/DWDefaultPaymentRequest.model';
import { DWDeletePayRequest } from 'src/app/shared/models/DWDeletePayRequest.model';
import { DwPaymentProfiles, PayinTokens } from 'src/app/shared/models/payintokens.model';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { UserService } from 'src/app/shared/services/user.service';
import { DynamicPopupComponent } from 'src/app/shared/components/dynamic-popup/dynamic-popup';


@Component({
  selector: 'app-manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.scss']
})
export class ManagePaymentComponent {
  paymenttokens: any;
  selectedtoken: any;
  cctokens: any;
  ectokens: any;
  dwdpatsession: DWDPATSessionModel = new DWDPATSessionModel;
  WalletID: any;
  paymentTokensAvailable: boolean = false;
  showSubMenu: boolean = true;
  frequency: any;
  isDefaultTokenAvailable:boolean=false;
  systemError: boolean = false;
  walletDetails:any;
  loading:boolean=true;
  disablebtn:boolean=false;
  InitialLoad:boolean=true;

  constructor(private router: Router,
    private modalService: NgbModal,
    public getpayintoken: GetPayinTokensService,
    public sessionService: GetGuidService,
    private dialog: MatDialog,public userService:UserService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSubMenu = event.url.startsWith('/preferences');
      }
      if (sessionStorage.length > 0) {
        this.WalletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
      }
    });
  }
  ngOnInit() {
    if (sessionStorage.length > 0) {
      this.WalletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    }
    this.walletDetails=this.userService.getWalletDetails();
    this.loadpayintokens();
  }

  updateDefaultPayment(): void {
this.disablebtn=true;
this.updateDefault();

      }
    
      updateDefault(){
    console.log(this.selectedtoken);
    if (this.selectedtoken != '') {
      let defaultTokenRequest: DWDefaultPaymentRequest = new DWDefaultPaymentRequest();
      defaultTokenRequest.token = this.selectedtoken;
      defaultTokenRequest.walletId = this.WalletID;
      this.getpayintoken.updatedefaulttoken(defaultTokenRequest).subscribe({
        next: (data: PayinTokens) => {
          console.log("default token is set is" + data.success);
          let config=new MatDialogConfig();
      config.data="UpdateConfirmed";
      let dialogRef=this.dialog.open(DynamicPopupComponent,config);
      dialogRef.afterClosed().subscribe(
        result => {
          
          if (result=='accept') {
            
            this.loadpayintokens();
            //this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
          }
          else{
            this.loadpayintokens();
          }
          
        }
      );
          
          
        }
      });

    }
  }

  //this.router.navigate(['/payment-tokens']);



  addPaymentMethod(): void {


    if (sessionStorage.length > 0) {
      this.WalletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    }


    this.dwdpatsession = new DWDPATSessionModel()
    this.dwdpatsession.action = 'addpayinpayment';
    this.dwdpatsession.callBackURL = `${ApiEndpoints.startWalletSessionCallBack}/preferences/manage-payment`;
    this.dwdpatsession.country = 'US';
    this.dwdpatsession.createdByUserId = 'appdw';
    this.dwdpatsession.locale = 'en';
    this.dwdpatsession.navigatingSystem = 'dw';
    this.dwdpatsession.paymentDirection = 'payin';
    this.dwdpatsession.walletID = this.WalletID;
    this.dwdpatsession.cashBalance=this.walletDetails?.cash?.totalCashBalance;
    this.dwdpatsession.rewardsBalance=this.walletDetails?.rewards?.totalRewardBalance;

    this.sessionService.createdwdpatwalletsession(this.dwdpatsession);
  }

  enableBtn() {
this.InitialLoad=false;
  }

  submitForm(form: NgForm) {
    if (form.valid) {
      console.log(form.value);
    }
  }

  toggleSubMenu(event: Event) {
    const clickedElement = event.target as HTMLElement;
    const isSubMenuLink = clickedElement.closest('li > ul a');

    if (isSubMenuLink) {
      // Keep the submenu open if a submenu link is clicked
      return;
    }

    this.showSubMenu = !this.showSubMenu;
    console.log('sub menu toggle');
  }


  updateNotification() {
    const modalRef = this.modalService.open(ModalContentComponent);
  }
  deleteToken(token: string) {
    
    this.systemError = false;
    let isDefaultPaymentMethod; // Pop-up needs to know if we are deleting the default payment method or not
    let tokenMatched = false; // We will not open pop-up unless it matches a token from paymenttokens list (it should, this is for extreme error handling)
    for (let i=0; i<this.paymenttokens?.length; i++) {
      if (this.paymenttokens[i].token == token) {
        isDefaultPaymentMethod = this.paymenttokens[i].isDefaultPaymentMethod?.toLowerCase() == 'true';
        tokenMatched = true;
      }
    }

    if (tokenMatched) {
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.data = {
        isDefaultPaymentMethod: isDefaultPaymentMethod
      };

      let dialogRef = this.dialog.open(DeletePopupComponent, config);

      dialogRef.afterClosed().subscribe(
        result => {
          if (result == 'confirm') {
            let dwDeletePayRequest: DWDeletePayRequest = new DWDeletePayRequest();
            dwDeletePayRequest.Token = token;
            this.getpayintoken.deletepaymentmethod(dwDeletePayRequest).subscribe({
              next: (data: PayinTokens) => {
                if (data?.success) {
                  this.loadpayintokens();
                } else {
                  this.systemError = true;
                  
                }
              }, error: (err) => {
                this.systemError = true;
                
              }
            });
          }
        }
      );
    } else {
      this.systemError = true;
      
    }
  }

  loadpayintokens() {
    let listoftokens = this.getpayintoken.fetchpayintokens(this.WalletID).subscribe({
      next: (data: PayinTokens) => {
                if (data.paymentMethods != undefined) {
          this.paymentTokensAvailable = true;
                  }
        else{
          this.loading=false;
          return
        }
        this.paymenttokens = data.paymentMethods;
        this.paymenttokens.forEach((token: any) => {
          token.cardImg = this.getpayintoken.getcardImage(token.cardType,token.ccholderName);
          
        });

        this.cctokens = this.paymenttokens.filter((x: any) => x.paymentMethod == 'CC');
        this.ectokens = this.paymenttokens.filter((x: any) => x.paymentMethod == 'ECP');
        let isDefaultPaymentMethod=this.paymenttokens.filter((x: any) => x.isDefaultPaymentMethod.toLowerCase() == 'true');
        if(isDefaultPaymentMethod.length>0){
          this.isDefaultTokenAvailable=true;

        }
        
        this.loading=false;
        this.disablebtn=false;
      }
    });

  }

}
