import { Component, OnInit, ViewChild } from '@angular/core';
import  jsonData from '../../../../assets/json/transactions.json';
import { ButtonClickService } from 'src/app/shared/services/button-click.service';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { TransactionHistoryService } from 'src/app/shared/services/api/transaction-history.service';
import { RewardEarn } from '../../../shared/models/transaction-history.interface';
import { RewardSpend } from '../../../shared/models/transaction-history.interface';
import { RewardCommon } from 'src/app/shared/models/RewardCommon';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { SessionService } from 'src/app/shared/services/session.service';
import { Route, Router } from '@angular/router';
import { transition } from '@angular/animations';



@Component({
  selector: 'app-all-rewards',
  templateUrl: './all-rewards.component.html',
  styleUrls: ['./all-rewards.component.scss']
})

export class AllRewardsComponent implements OnInit {

 dataSource = new MatTableDataSource<RewardCommon>([]);

  displayedColumns: string[] = [];
 // dataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selection = new SelectionModel<RewardCommon>(true, []);
  searchValue: string = '';

  rewardType: string = 'earned';
  walletID: string = '';
  walletRole: string = ''  

  autoPay: number = 0;
  cashEligible = false;
  rewardEligible = false;
  dataLoaded = false;

  constructor(
    private buttonClick: ButtonClickService,
    private walletSummaryService: WalletSummaryService,
    private transactionHistoryService: TransactionHistoryService
    ){}

  transactions = jsonData;
  walletSummary: WalletSummary | null = null;

  selectAll(event: Event) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    
    this.transactions.forEach(transaction => {
      transaction.selected = isChecked;
    });
  }

  goToRedeem(){
    this.buttonClick.goToRedeem();
  }

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
        this.walletID = summary.wallet.walletID;
        this.walletRole = 'admin'; // Replace 'yourRole' with the actual logic to determine walletRole
        
        const isDWAutoPayValue = Number(summary.wallet.isDWAutoPay);
        this.autoPay = isDWAutoPayValue;
        
        this.walletSummary = summary;
        this.dataLoaded = true;
        this.fetchRewards();
      }
    });

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dateString = new Date(data.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return dateString.toLowerCase().includes(filter.toLowerCase());
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearch(searchValue: string) {
    const formattedSearchValue = searchValue.trim().toLowerCase();
    this.dataSource.filter = formattedSearchValue;
   // this.dataSource.filter = searchValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchRewards(): void {
    this.transactionHistoryService.fetchTransactionHistory(this.walletID, this.walletRole, 'rewards').subscribe(
      (data) => {
        if (this.rewardType === 'earned') {   
          // Sort by earnDate in descending order
          const sortedData = this.transformData(data.walletTransactionsList.rewards.rewardsEarn, true)
            .sort((a, b) => new Date(b.earnDate).getTime() - new Date(a.earnDate).getTime());
          this.dataSource = new MatTableDataSource(sortedData);
          this.displayedColumns = ['select', 'earnDate', 'pointsEarned', 'earnCategory', 'earnCategoryDescription' ];
        } else if (this.rewardType === 'redeemed') {
          // Sort by spendDate in descending order
          const sortedData = this.transformData(data.walletTransactionsList.rewards.rewardsSpends, false)
            .sort((a, b) => new Date(b.spendDate).getTime() - new Date(a.spendDate).getTime());
          this.dataSource = new MatTableDataSource(sortedData);
          this.displayedColumns = ['select', 'spendDate', 'pointSpend', 'spendCategory', 'spendIdentifierDescription'];
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.error('Error fetching rewards:', error);
      }
    );
  }
  
  // transformData(data: any[], isEarn: boolean): any[] {
  //   // Transform the data as needed
  //   return data;
  // }

  transformData(data: any[], isEarn: boolean): any[] {
    return data.map((item) => ({
      ...item, // Keep the existing properties
      earnCategoryDescription: isEarn && item.earnCategoryDescription === 'RewardsEarnProfileValidation'
        ? 'Online Portal Profile Validation'
        : item.earnCategoryDescription
        
    }));
  }
  

  onRewardTypeChange(rewardType: string): void {
    const walletID = sessionStorage.getItem('WalletID');
    const walletRole = sessionStorage.getItem('WalletRole');

    this.rewardType = rewardType;
    this.displayedColumns = this.getDisplayedColumns();
   
    if (walletID && walletRole) {
      this.fetchRewards();
    } else {
      console.error('Wallet ID or Wallet Role is missing');
    }
  }
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: RewardCommon): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.date} ${row.amount}`;
  }
  

  getDisplayedColumns(): string[] {
    if (this.rewardType === 'earned') {
      return ['select', 'earnDate', 'pointsEarned', 'earnCategory', 'earnIdentifier'];
    } else if (this.rewardType === 'redeemed') {
      return ['select', 'spendDate', 'pointSpend', 'spendCategory', 'spendIdentifierDescription'];
    } else {
      // Default or error case
      return [];
    }
  }
  
  
  

  }
  
  
  


