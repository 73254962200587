import { Component, Renderer2, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { TransferFlyoutComponent } from '../transfer-flyout/transfer-flyout.component';
import { PayoutTokensService } from 'src/app/shared/services/api/get-payout-tokens.service';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { BankDetails, DWPayoutToken, PayoutTokens, PayoutTokensResponse, transferCarrier } from 'src/app/shared/models/payouttokens.model';
import { CurrencyPipe, DatePipe, formatCurrency } from '@angular/common';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DynamicPopupComponent } from 'src/app/shared/components/dynamic-popup/dynamic-popup';
import { param } from 'jquery';
import { AutoTransfer } from 'src/app/shared/models/AutoTransfer.model';
import { DeactivateTransferPopUpComponent } from '../deactivate-transfer-pop-up/deactivate-transfer-pop-up.component';
import { AutoTransferFlyoutComponent } from '../auto-transfer-flyout/auto-transfer-flyout.component';

@Component({
  selector: 'app-auto-transfer',
  templateUrl: './auto-transfer.component.html',
  styleUrls: ['./auto-transfer.component.scss']
})
export class AutoTransferComponent implements OnInit {
  [x: string]: any;

  currentBalance: string = '';
  cashBalance: string = '';
  amount: string = '';
  paymenttokens: DWPayoutToken[] = [];
  payoutaccounts: any;
  selectedtoken: string = '';
  showzelle: boolean = false;
  noninstantdiv: any;
  instantdiv: any;
  errorMessage: any = '';
  validationMessage: any = '';

  walletSummary: WalletSummary | null = null;
  walletId: string = '';
  walletRole: string = '';
  autoPay: number = 0;
  dwdpatsession: any;
  nopayments: boolean = true;

  //@ViewChild(TransferFlyoutComponent, { static: true }) transferFlyoutComponent!: TransferFlyoutComponent;
  walletDetails: any;
  isLastOption: boolean = false;
  rewardsBalance: string = '';
  loading: boolean = true;
  holdbtn: boolean = false;
  lastTransferType: string = '';

  showBanner: boolean = true;
  autoTransferToggle: boolean = false;
  toggleText: string = 'Off'; 
  transferType:string='';
  defaultToken:string='';

  constructor(public sessionservice: GetGuidService, public route: Router, private renderer: Renderer2, private el: ElementRef, public payouttokens: PayoutTokensService, public routeActivate: ActivatedRoute, public walletSummaryService: WalletSummaryService, public dialog: MatDialog) {

  }
  


  ngAfterContentInit() {
    
    this.getpayouttokens();

  }

  
  ngAfterViewChecked() {
   
    this.noninstantdiv = document.getElementById('non-instant');
    this.instantdiv = document.getElementById('instant');
    this.instantdiv?.classList.add('selected');
    this.noninstantdiv?.classList.remove('selected');
    if (this.lastTransferType.toLowerCase() == 'nonrtp') {
      this.noninstantdiv?.classList.add('selected');
      this.instantdiv?.classList.remove('selected');
    }
    else {
      this.instantdiv?.classList.add('selected');
      this.noninstantdiv?.classList.remove('selected');
    }
    
    if(document.getElementById('non-instant') !=null)
    {
    if(this.transferType.toLowerCase()=='rtp')
    {
      document.getElementById('non-instant').style.display='none';
    }
    else{
      document.getElementById('instant').style.display='none';
    }
  }
    
  }

  removeAlpha() {
    this.amount = this.amount.replace(/[a-zA-Z~@#^*()_+=[\]{}|\\,?:; -!%&`/"'<>|]/, '');
  }


  ngOnInit():void {
    const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');
  if (autoTransferData) {
    this.autoTransferToggle = autoTransferData.isAutoTransfer.toString().toLowerCase()=="true"?true:false;
    this.transferType = autoTransferData.transferType;
    this.defaultToken = autoTransferData.defaultToken;
  }

  this.getpayouttokens();

    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      this.currentBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
      this.cashBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
      this.amount = formatCurrency((parseFloat)(parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2)), 'en-us', '$');
      this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
      this.rewardsBalance = parseFloat(summary?.wallet?.rewards?.totalRewardBalance).toFixed(2);
      console.log("AutoPay from Transfer Balance: ", this.autoPay);


    });


    this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
    this.cashBalance = parseFloat(this.walletDetails.cash.totalCashBalance.toString()).toFixed(2);
    this.currentBalance = parseFloat(this.cashBalance.toString()).toFixed(2);
    this.amount = formatCurrency((parseFloat)(parseFloat(this.currentBalance.toString()).toFixed(2)), 'en-us', '$');




  }

  
  getpayouttokens() {
    let payouttokens: PayoutTokens = new PayoutTokens();
    
    if ( this.transferType && this.transferType.toLowerCase()=='rtp') {
      this.transferType = 'RTP';
      this.lastTransferType = 'RTP';
    }
    else {
      this.transferType = 'NonRTP';
      this.lastTransferType = 'NonRTP';
    }
    payouttokens.transferType = this.transferType;
    payouttokens.walletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    payouttokens.walletRole = JSON.parse(sessionStorage.getItem("WalletRole") || '{}');

    const walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
    const payeeID = walletDetails?.autotransfer?.payeeID || null;

    if (!payeeID) {
      console.error('payeeID is missing in walletDetails');
      return; // Exit if payeeID is missing
    } else {
     console.log("Payee ID:", payeeID);
    }

    let listoftokens = this.payouttokens.fetchpayouttokens(payouttokens).subscribe({
      next: (data: PayoutTokensResponse) => {

        this.nopayments = true;
        if (data.success == true)
          this.nopayments = false;
          this.paymenttokens = data.payoutTokens.filter((x: any) => x.payeeID == this.defaultToken);
          
        this.paymenttokens.forEach((token: any) => {
        
          if (token.zelleIndicator == 'T') {
            token.cardImg = this.payouttokens.getcardImage('zelle');

          }
          else {
            token.counterpartyPayOrg = token.counterpartyPayOrg.replace('NA', '');
            token.cardImg = this.payouttokens.getcardImage(token.counterpartyPayOrg);
            if (token.counterPartyAccountNumber != null && token.counterPartyAccountNumber != undefined) {
              let accnumber = token.counterPartyAccountNumber;
              token.counterPartyAccountNumber = accnumber.substring(accnumber.length - 4, accnumber.length + 1);
            }
            else {
              token.counterPartyAccountNumber = '0000';
            }
          }
          /*  let expiration=token.ccexpiration;
           token.ccexpiration=expiration.slice(4,6)+"/"+ expiration.slice(2,4) */
        
        
        
        });
        
        this.payoutaccounts = this.paymenttokens;
        this.loading = false;


      }

    });

  }
  
  clearAmount(): void {
    this.amount = "$";
  }

  updateBalance(): void {
    if (document.getElementById("transferbtn") != null) {
      this.validationMessage = '';
      let amt = this.amount.toString().replaceAll('$', '').replaceAll(',', '');

      document.getElementById("transferbtn").removeAttribute('disabled');
      if (amt != '')
        this.amount = formatCurrency(parseFloat(amt), 'en-us', '$');

      let inputsection = document.getElementById("inputsection");
      let underline = document.getElementsByClassName("underline")[0];

      let cashbalance = document.getElementById("cashbalance");
      let clearicon = document.getElementsByClassName("clear-icon")[0];


      if (parseFloat(this.cashBalance.toString()) < parseFloat(amt)) {
        inputsection.classList.add("error");
        clearicon.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        this.validationMessage = " Please type an amount less than your balance.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');

        return
      }
      else if (parseFloat(amt) <= 0 || amt == '') {
        inputsection.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        inputsection.classList.add("error");
        this.validationMessage = " Please enter an amount more than 0.00.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');
        return
      }
      else if (document.getElementById('instant')?.classList.contains('selected') && parseFloat(amt) <= 3) {
        inputsection.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        inputsection.classList.add("error");
        this.validationMessage = "For Instant transfer, Please enter an amount more than 3.00.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');
        return
      }

      else if (parseFloat(amt) <= parseFloat(this.cashBalance)) {
        inputsection.classList.remove("error");
        underline.classList.remove("error");
        cashbalance.classList.remove("error");
        clearicon.classList.remove("error");
        this.currentBalance = this.cashBalance;
        document.getElementById("transferbtn").removeAttribute('disabled');
        return
      }
      else {

      }
    }

  }

  goToReview() {
    this.holdbtn = true;
    if (document.getElementById('non-instant')?.classList.contains('selected')) {
      let config = new MatDialogConfig();
      config.data = "NonInstantConfirmation";
      let dialogRef = this.dialog.open(DynamicPopupComponent, config);
      dialogRef.afterClosed().subscribe(
        result => {
          this.holdbtn = false;
          if (result == 'close') {

            this.reviewTranfer();
            //this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
          }

        }
      );
    }
    else {
      this.reviewTranfer();
    }
  }
  reviewTranfer() {
    this.holdbtn = true;
    let transferType: any, charge: any, totaltransfer: any;
    let amt = this.amount.toString().replace(/^\$/, '').replaceAll(",", "");


    if (this.selectedtoken == "" || this.selectedtoken == undefined) {
      this.errorMessage = "Please select an account to transfer funds.";
      this.holdbtn = false;
      return
    }
    else {

      if (document.getElementById('instant')?.classList.contains('selected')) {
        transferType = 'RTP';
        let charge1 = (parseFloat(amt) * 0.015).toFixed(2);
        charge = formatCurrency(parseFloat(charge1), 'en-us', '$');
        let totaltransfer1 = (parseFloat(amt) - (parseFloat(amt) * 0.015)).toFixed(2);
        totaltransfer = formatCurrency((parseFloat)(totaltransfer1), 'en-us', '$');

      } else {
        transferType = 'NonRTP';
        charge = formatCurrency(0, 'en-us', '$');
        let totaltransfer1 = parseFloat(amt).toFixed(2).toString();
        totaltransfer = formatCurrency((parseFloat)(totaltransfer1), 'en-us', '$');
      }
      let objTransferCarrier: transferCarrier = new transferCarrier();
      let token: any = this.paymenttokens.filter((x: any) => x.payeeID == this.selectedtoken);



      let bankDetails: BankDetails = new BankDetails();
      if (token[0].achIndicator == 'T') {
        bankDetails.accountDetails = "Bank **** " + token[0].counterPartyAccountNumber;
        bankDetails.bankName = token[0].counterpartyPayOrg + " " + token[0].counterPartyBankAccountCategory + " Banking";
        bankDetails.imgSrc = token[0].cardImg;
      }
      else {
        bankDetails.accountDetails = token[0].counterPartyAccountNumber;
        bankDetails.bankName = "ZELLE";
        bankDetails.imgSrc = token[0].cardImg;
      }
      objTransferCarrier.token = this.selectedtoken;
      objTransferCarrier.accountType = transferType;
      objTransferCarrier.charges = charge;
      objTransferCarrier.totalTransferAmount = totaltransfer;
      objTransferCarrier.transferAmount = this.amount;
      objTransferCarrier.bankDetails = bankDetails;

      sessionStorage.setItem("transferCarrier", JSON.stringify(objTransferCarrier));
      this.route.navigate(['my-wallet', 'my-cash', 'review-summary']);
    }
  }
  // onToggleChange(event: any) {
  //   this.toggleText = this.autoTransferToggle ? 'On' : 'Off'; 
  // }

  onToggleChange(event: any): void {
    if (!this.autoTransferToggle) {
      const dialogRef = this.dialog.open(DeactivateTransferPopUpComponent, {
        width: '832px',
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const updatePayload = {
            walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
            walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!),
            isDWAutoTransfer: false,
          };
  
          this.walletSummaryService.updateWalletSummary(updatePayload).subscribe({
            next: (response) => {
              console.log('Auto Transfer deactivated successfully.');
            },
            error: (error) => {
              console.error('Error updating Auto Transfer:', error);
              this.autoTransferToggle = true; // Revert toggle in case of error
            },
          });
        } else {
          // User canceled, revert the toggle state to ON
          this.autoTransferToggle = true;
        }
      });
    }
  }
  

  updateAutoTransfer(status: boolean): void {
    const request = {
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!),
      isDWAutoTransfer: status, // Send the updated value of isDWAutoTransfer
    };
  
    this.walletSummaryService.walletSummary$.subscribe({
      next: (response) => {
        if (response.success) {
          console.log('Auto Transfer updated successfully.');
        } else {
          console.error('Failed to update Auto Transfer status.');
          // Revert toggle in case of failure
          this.autoTransferToggle = !status;
        }
      },
      error: (error) => {
        console.error('Error updating Auto Transfer:', error);
        // Revert toggle in case of error
        this.autoTransferToggle = !status;
      },
    });
  }

  
  
    

  selectedtile(isinstant: boolean): void {
    let instantdiv, noninstantdiv;
    this.loading = true;
    if (isinstant) {
      this.showzelle = false;
      this.noninstantdiv?.classList.remove('selected');
      this.instantdiv?.classList.add('selected');


    }
    else {
      this.showzelle = true;
      this.instantdiv?.classList.remove('selected');
      this.noninstantdiv?.classList.add('selected');


    }
    this.getpayouttokens();

  }

  gotoPayMyBill() {

    this.route.navigate(['/my-wallet/all-rewards/redeem'], { relativeTo: this.routeActivate });
  }
  addPaymentMethod(): void {
    this.holdbtn = true;

    if (sessionStorage.length > 0) {
      this.WalletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    }


    this.dwdpatsession = new DWDPATSessionModel()
    this.dwdpatsession.action = 'addpayoutpayment';
    this.dwdpatsession.callBackURL = `${ApiEndpoints.startWalletSessionCallBack}/my-wallet/my-cash/transfer-balance`;
    this.dwdpatsession.country = 'US';
    this.dwdpatsession.createdByUserId = 'appdw';
    this.dwdpatsession.locale = 'en';
    this.dwdpatsession.navigatingSystem = 'dw';
    this.dwdpatsession.paymentDirection = 'payout';
    this.dwdpatsession.walletID = this.WalletID;
    this.dwdpatsession.cashbalance = this.currentBalance;
    this.dwdpatsession.rewardsBalance = this.rewardsBalance;

    this.sessionservice.createdwdpatwalletsession(this.dwdpatsession);
    this.holdbtn = false;
  }

  blurRest() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    let dialogRef = this.dialog.open(AutoTransferFlyoutComponent, config);
  }

  enableBtn() {

    this.errorMessage = '';
  }

  closeBanner(): void {
    this.showBanner = false;
  }


}
