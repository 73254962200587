<div class="sub-container">
    <h2 class="headline-highlight">My Wallet Details</h2>

    <div class="balance-container">     
      
        <div class="balance-details">

            <div class="wallet-details">    
                <div class="balance-blocks">
                    <div class="title">REWARDS</div>
                        <div class="content">
                            <span class="svg"><img src="../../../../assets/icons/star_rate.svg"></span>
                            <span class="value">{{ walletSummary?.wallet?.rewards?.totalRewardBalance ?? 0 | number }}</span>
                            <span class="pts">PTS</span>
                        </div>
                    <div class="more">{{  walletSummary?.wallet?.rewards?.totalRewardValue ?? 0 | currency}} Value</div>
                </div>

                <div class="divider"></div>
                
                <div class="balance-blocks">
                    <div class="title">CASH</div>
                        <div class="content">
                            <span class="svg"><img src="../../../../assets/icons/monetization_on.svg"></span>
                            <!-- <span class="value">{{ walletSummary?.wallet?.cash?.totalCashBalance ?? 0 | currency}}</span> -->
                            <span class="value">{{ walletSummary?.wallet?.cash?.totalCashBalance| currency}}</span>
                            <span class="pts"></span>
                        </div>       
                </div>
            </div>
   
            <div class="wallet-details">    
                <div class="button-container">
                    <div class="btn" *ngIf="dataLoaded && !(autoPay === 1)">
                        <button (click)="redeemPay()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                            <span class="mdc-button__label">
                                <span class="button-label">
                                    <span>Redeem</span>
                                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            </div>
        </div>  
        
        <h2 class="headline-highlight">Transaction History</h2>

        <div class="search-box-container box-border">
          <div class="search-container">

            <mat-form-field class="custom-placeholder">
            <mat-icon matPrefix>search</mat-icon>
              <input class="search-box" matInput placeholder="Search Table" [(ngModel)]="searchValue" (keyup.enter)="applySearch(searchValue)">
            </mat-form-field>
        
            <div class="button-container">
              <div class="btn">
                <button (click)="applySearch(searchValue)" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                  <span class="mdc-button__label">
                    <span class="button-label">
                      <span>Search</span>
                      <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        
          <div class="radio-buttons">
            <mat-radio-group [(ngModel)]="rewardType" (change)="fetchData()">
              <mat-radio-button value="earned">Wallet Earning</mat-radio-button>
              <mat-radio-button value="redeemed">Wallet Spending</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef class="dw-header-cell">
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              disableRipple>
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              disableRipple>
                </mat-checkbox>
              </td>
            </ng-container>
        
            <!-- Common Columns -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Date</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date : "MM/dd/YYYY" }}</td>
            </ng-container>
        
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.isCash; else pointsTemplate">
                  {{ element.amount | currency }}
                </ng-container>
                <ng-template #pointsTemplate>
                  {{ element.amount | number }} pts
                </ng-template>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Funds Type</th>
              <td mat-cell *matCellDef="let element">{{ element.fundsType }}</td>
            </ng-container>

            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Details</th>
              <td mat-cell *matCellDef="let element" [innerHTML]="element.detail"></td>
            </ng-container>

            <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Reference Number</th>
              <td mat-cell *matCellDef="let element">
                {{ element.identifier }}
              </td>
            </ng-container>
            
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Account</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.fundsType === 'Cash' && element.identifierDescription === 'PayableReferenceNumber'; else noAccount">
                  <ng-container *ngIf="element.accountBankName; else emptyBankName">
                    <ng-container *ngIf="element.accountBankName === 'N/A'; else normalBankName">
                      {{ element.accountBankName }} *** {{ element.accountAlias }}
                    </ng-container>
                    <ng-template #normalBankName>
                      {{ element.accountBankName }} *** {{ element.accountAlias }}
                    </ng-template>
                  </ng-container>
                  <ng-template #emptyBankName>
                    ' ' <!-- Displays an empty string if accountBankName is empty -->
                  </ng-template>
                </ng-container>
                <ng-template #noAccount>N/A</ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>
            
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" class="custom-paginator" showFirstLastButtons></mat-paginator>
        </div>
        

</div>
