<div class="transfer-popup-container">
<div mat-dialog-content>
    <button class="close" (click)="onCancel()"><img src="../../../../assets/icons/close icon.svg"></button>
    <div class="sub-container">
        <h2 class="headline-highlight">Turn on Auto Transfer for Hassle-Free Payments!</h2>

        <p>By clicking "Yes, Turn on Auto Transfer," you agree to automatically transfer all approved claim payouts from your UPS Wallet to your 
            specified bank account upon approval.</p> 
            
        <p>Please keep in mind that enabling this feature means you won’t have access to your cash balance for invoice payments and you 
            will lose the 1% cash back benefit for using your cash to pay invoices.</p>
            
         <p>Want to proceed? Click the button below!</p>

    </div>

</div>

  <div class="button-container">
    <div class="btn">
        <button mat-button (click)="onConfirm()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
            <span class="mdc-button__label">
                <span class="button-label">
                  <span>Yes, Turn on Auto Transfer</span>
                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>                           
                </span>
            </span>
        </button>
    </div>
    <div class="btn">
        <button (click)="onCancel()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
            <span class="mdc-button__label">
                <span class="button-label">
                  <span class="cancel">No, I Prefer to Transfer Manually</span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                </span>
            </span>
        </button>
    </div>
</div>
  
</div>