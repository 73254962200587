<div class="page-container">
    <div *ngIf="loading" class="loading-container">
        <div class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
    <div class="content-container" *ngIf="!loading">
        <h2 class="headline-highlight">Manage Payment Methods</h2> 
        <p>Manage payment methods for automatic payments and redemption</p>    
 
        <div class="box-border">
            <h4>Payment Methods</h4>
            <div *ngIf="isDefaultTokenAvailable"><p>Select One</p></div>
            <div *ngIf="!isDefaultTokenAvailable" class="no-default"><p>Select One Default Payment Method *</p></div>
            <p>ACH</p>
            <div *ngIf="ectokens==undefined || ectokens==''" class="sections">
                <label class="noachtookens" >No ACH on file</label>
               <div class="chargestxt"> <label class="chargeslbl" >Earn </label> <label class="percentage">0.75%</label> <label class="chargeslbl" > back in rewards each time you pay with ACH</label></div>
              </div>
              <div *ngIf="ectokens!=undefined && ectokens!=''  ">
            <div *ngFor="let ectoken of ectokens" class="tokendiv">
              
                <input type="radio" class="form-check-input" [(ngModel)]="selectedtoken" (click)="enableBtn()" value="{{ectoken.token}}"/>  <img class="pay-icon bank-icons"src="{{ectoken.cardImg}}">
              
                <div class="info">
                  <span >{{ectoken.ccholderName}}</span>
                    <span >**** {{ectoken.last4Digits}}</span>
                    <span><label class="chargeslbl" >Earn</label> <label class="percentage">0.75%</label> <label class="chargeslbl" >back in rewards each time you pay with ACH</label></span>
                </div>
                <div *ngIf="ectoken.isDefaultPaymentMethod.toLowerCase()=='true'" class="default-div">
                    <label class="default">Default</label>
                </div>
                <div class="delete">
                    <img (click)="deleteToken(ectoken.token)" class="delete-icon" src="../../../../assets/icons/delete.svg">
                </div>
            </div>
              </div>
            <hr>
            <p>Credit Card</p>
            <div *ngIf="cctokens==undefined || cctokens==''" class="sections">
                <label class="noachtookens" >No credit card on file</label><br>
              </div>
              <div *ngIf="cctokens!=undefined && cctokens!=''">
            <div *ngFor="let cctoken of cctokens" class="tokendiv">
              
                <input type="radio" class="form-check-input" [(ngModel)]="selectedtoken" value="{{cctoken.token}}" (click)="enableBtn()"/>  <img class="pay-icon bank-icons" src="{{cctoken.cardImg}}">
              
                <div class="info">
                  <span >{{cctoken.cardType}}</span>
                    <span >**** {{cctoken.last4Digits}}</span>
                                   </div>
                <div *ngIf="cctoken.isDefaultPaymentMethod.toLowerCase()=='true'" class="default-div">
                    <label class="default">Default</label>
                </div>
                
                <div class="delete">
                    <img (click)="deleteToken(cctoken.token)" class="delete-icon" src="../../../../assets/icons/delete.svg">
                </div>
            </div>
              </div>
              <div class="add-payment" (click)="addPaymentMethod()">
                <img src="../../../../assets/icons/add.svg"><a>Add New Payment Method</a>
            </div>   

           
        </div>
        
        
        <div class="btn" >
            <button (click)="updateDefaultPayment()" *ngIf="!disablebtn && !InitialLoad" class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Update My Default Payment</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
            <button (click)="updateDefaultPayment()"  *ngIf="disablebtn && !InitialLoad" disabled class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Update My Default Payment</span>
                        &nbsp;&nbsp;<span class="spinner-border"></span>
                    </span>
                </span>
            </button>
            <button (click)="updateDefaultPayment()"  *ngIf="InitialLoad" disabled class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Update My Default Payment</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>

        <div class="error" *ngIf="systemError">
            Something went wrong! Please try again later.
        </div>


  </div>
  </div>
  
     

