import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AutoRedeemDialogComponent } from '../auto-redeem-dialog/auto-redeem-dialog.component';
import { ModalDefaultPaymentComponent } from 'src/app/shared/components/modal-default-payment/modal-default-payment.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserService } from 'src/app/shared/services/user.service';
import { GetPayinTokensService } from 'src/app/shared/services/api/get-payin-tokens.service';
import { GetAutoPaySequenceService } from 'src/app/shared/services/api/get-auto-pay-sequence.service';
import { filter, findIndex } from 'rxjs';

@Component({
  selector: 'app-auto-redeem',
  templateUrl: './auto-redeem.component.html',
  styleUrls: ['./auto-redeem.component.scss']
})


export class AutoRedeemComponent implements OnInit {
  private modalOpened: boolean = false; // Add this flag
  isOn = false;
@Output() toggleChange = new EventEmitter<boolean>();

toggle() {
  this.isOn = !this.isOn;
  this.toggleChange.emit(this.isOn);
}

  cardType: any;
  lastFourDigits: any;
  ccExpiration: any;
  isExpired: boolean = false;
  isWarning: boolean = false;

  ONLSessionDetails: any;
  walletDetails: any;

  autopayOptions: any;
  currentSelection: any;
  autopayToggle: boolean = false;
  toggleText: string = 'Off';  // Default toggle text

  loading: boolean = false;
  systemError: boolean = false;
  defaultError: boolean = false;
  is4uError: boolean = false;
  successMessage: boolean = false;
  disableButton: boolean = false;
  buttonLoading: boolean = false;
  initialState: any;
  isAutoTransferOn:boolean=false;

  @Output() autopayResult = new EventEmitter<any>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public route: ActivatedRoute,
    private userService: UserService,
    public getpayintoken: GetPayinTokensService,
    private getAutoPaySequence: GetAutoPaySequenceService
  ) {
    this.ONLSessionDetails = this.userService.getONLSessionDetails();
    // I would want to pull this item directly from sessionStorage, however in fear of potential sync issues I am going to make another call for now in ngOninit
    // this.walletDetails = this.userService.getWalletDetails();
  }

  // Function to handle drag and drop/rearranging of items in the array
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex == 0 && event.currentIndex == 2) {
      // The item in index 2 (default payment method) cannot be moved out of index 2, so do not swap 0 and 2 here
      // However, still swap 0 and 1 in case the user was intending to swap the first two items, but accidentally dragged a bit too low
      moveItemInArray(this.autopayOptions, 0, 1);
    } else if (event.previousIndex == 1 && event.currentIndex == 2) {
      // Do nothing (cannot move the item in index 1 into index 2. The index 2 item is immoveable and must remain there at all times)
    } else {
      moveItemInArray(this.autopayOptions, event.previousIndex, event.currentIndex);
    }
  }

  ngOnInit() {
    this.getWalletSummaryAndLoadPage();
    this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
    let autotransfer = JSON.parse(sessionStorage.getItem('AutoTransfer')!);
    this.isAutoTransferOn=autotransfer.isAutoTransfer;
  }

  checkDefaultPaymentMethod(): void {
    // Assuming you have a method to check if the default payment method is set
    const isDefaultPaymentMethodSet = this.findDefaultPaymentMethod();

    if (!this.findDefaultPaymentMethod) {
      // Redirect to home page with a query parameter to show the modal
      this.router.navigate(['/home'], { queryParams: { showDefaultPaymentModal: true } });
    }
  }

  // Function to get wallet summary from either the API or sessionStorage. Afterwards, load the page
  getWalletSummaryAndLoadPage() {
    this.loading = true;
    this.systemError = false;
    this.defaultError = false;
    this.successMessage = false;
    this.disableButton = false;

    let request = {
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!)
    }
    this.userService.getWalletSummary(request).subscribe(
      data => {
        // Retrieve wallet summary details and continue loading the page
        if (data?.wallet) {
          sessionStorage.setItem('walletDetails', JSON.stringify(data.wallet));
          this.walletDetails = data.wallet;
          this.loadAutoPayOptions();
        } else {
          // Try to retrieve information from sessionStorage.
          this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        }

        if (this.walletDetails) {
          this.loadAutoPayOptions();
        } else {
          // We weren't able to get the wallet details. Disable the update autopay button and display error message
          this.loading = false;
          this.disableButton = true;
          this.systemError = true;
        }
      }, error => {
        // Even if the API fails, we can try to retrieve the information from sessionStorage
        this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        if (this.walletDetails) {
          this.loadAutoPayOptions();
        } else {
          // We weren't able to get the wallet details. Disable the update autopay button and display error message
          this.loading = false;
          this.disableButton = true;
          this.systemError = true;
        }
      }
    );
  }

  // Function to determine if the user should be allowed to view the autopay screen or not.
  // If so, display the 3 autopay options in the correct order. If not, navigate them away from the page or display an error message.
  loadAutoPayOptions() {
    if (!(this.ONLSessionDetails?.productSystemIdentifier1 && this.ONLSessionDetails?.productSystemIdentifier2 && this.ONLSessionDetails?.productSystem)) {
      // If any of these three fields are missing, we probably should log the user out. For now, just display the error message.
      this.loading = false;
      this.disableButton = true;
      this.systemError = true;
    } else if (this.walletDetails?.defaultPaymentMethod?.toLocaleLowerCase() != 'true') {
      // If user does not have a default payment method set, prompt them with a pop-up that they need to set a default payment method.
      this.loading = false;
      this.disableButton = true;
      this.defaultError = true;
      this.openSetDefaultPaymentModal();
      this.router.navigate(['/home'], { queryParams: { showDefaultPaymentModal: true } });
    } else if (this.walletDetails?.rewardEligible?.toLocaleLowerCase() != 'true') {
      // If user is IS4U user (aka rewardEligible is not true), they are not allowed to see the page.
      this.loading = false;
      this.disableButton = true;
      this.is4uError = true;
    } else if (this.walletDetails?.payment?.cardType && this.walletDetails?.payment?.last4Digits && this.walletDetails?.token) {
      // User is allowed to view the autopay page, and has their default payment method info in walletDetails
      this.setDefaultPaymentInfo();
      this.setOrderForAutopayOptions();
    } else {
      // User has defaultPaymentMethod = 'true', but for some reason, the payment object was blank so we don't have full info on their set default payment method
      // Call DWGetTokenInfo to get the full list of payment methods and find the default one from there
      // If for some reason this API fails we'll still load the page but the user will be barred from enrolling in autopay with their default payment method (since we don't know what it is)
      this.findDefaultPaymentMethod(); // If default payment method is found, we will set the order for autopay options then
    }
  }

  findDefaultPaymentMethod() {
    let walletID = JSON.parse(sessionStorage.getItem('WalletID')!);
    this.getpayintoken.fetchpayintokens(walletID).subscribe(
      data => {
        if (data?.paymentMethods) {
          let allMethods = data.paymentMethods;
          let defaultMethod = allMethods?.find(x => x.isDefaultPaymentMethod == "true");
          
          if (defaultMethod) {
            this.cardType = defaultMethod.cardType;
            this.lastFourDigits = defaultMethod.last4Digits;
            this.ccExpiration = defaultMethod.ccexpiration;

            if (this.cardType == 'Visa' || this.cardType == 'Mastercard' || this.cardType == 'Amex') {
              this.isExpired = this.checkCCExpiration(defaultMethod.ccexpiration, 'expired');
              this.isWarning = this.checkCCExpiration(defaultMethod.ccexpiration, 'warning');

              if (this.isExpired || this.isWarning) {
                // If it's expired or close to expiring, don't allow them to continue, show error message.
                this.loading = false;
                this.disableButton = true;
              }
            }

            this.setOrderForAutopayOptions();
          } else {
            // If user does not have a default payment method set, prompt them with a pop-up that they need to set a default payment method.
            this.loading = false;
            this.disableButton = true;
            this.defaultError = true;
            this.openSetDefaultPaymentModal();
          }
        } else {
          // We couldn't find their default payment method, treat as system error.
          this.loading = false;
          this.disableButton = true;
          this.systemError = true;
        }
      }, error => {
        // We couldn't find their default payment method, treat as system error.
        this.loading = false;
        this.disableButton = true;
        this.systemError = true;
      }
    );
  }

  setDefaultPaymentInfo() {
    this.cardType = this.walletDetails?.payment?.cardType;
    this.lastFourDigits = this.walletDetails?.payment?.last4Digits;
    this.ccExpiration = this.walletDetails?.payment?.ccExpiration;

    if (this.cardType == 'EC' || this.cardType == 'ECP') {
      // No cc expiration check needed for ACH payments
    } else if (this.walletDetails?.payment?.ccExpiration) {
      // Perform cc expiration check
      this.isExpired = this.checkCCExpiration(this.walletDetails?.payment?.ccExpiration, 'expired');
      this.isWarning = this.checkCCExpiration(this.walletDetails?.payment?.ccExpiration, 'warning');

      if (this.isExpired || this.isWarning) {
        // If it's expired or close to expiring, don't allow them to continue, show error message.
        this.loading = false;
        this.disableButton = true;
      }
    }
  }

  setOrderForAutopayOptions() {
    if (this.walletDetails?.isDWAutoPay == '0') {
      // If they don't have autopay enabled, load the screen with all 3 options unchecked and not draggable
      this.loading = false;
      this.autopayToggle = false;
      this.toggleText = 'Off';
      this.autopayOptions = [
        {key: 'rewards', checked: false, disabled: false},
        {key: 'cash', checked: false, disabled: false},
        {key: 'default', checked: false, disabled: true},
      ];
      if (this.isAutoTransferOn) {
        const filteredArray = this.autopayOptions.filter(obj => obj.key !== 'cash');
        this.autopayOptions = filteredArray;
      }
    } else if (this.walletDetails?.isDWAutoPay == '1') { 
      // If they do have autopay enabled already, call getAutoPaySequence to get the current order of selected autopay options
      let request = {
        walletID: this.walletDetails?.walletID,
        walletRole: 'admin'
      }
      this.userService.getAutoPaySequence(request).subscribe(
        data => {
          this.loading = false;
          if (data) {
            // There are two possible scenarios where cash should be displayed before, both occur when cashSequence == 1
            if (data?.cashSequence == '1') {
              // Cash goes before rewards
              this.autopayToggle = true;
              this.toggleText = 'On';
              this.autopayOptions = [
                {key: 'cash', checked: data?.isCashSelected, disabled: false},
                {key: 'rewards', checked: data?.isRewardsSelected, disabled: false},
                {key: 'default', checked: true, disabled: true},
              ];          
            } else {
              // For any other scenario, use the default order (which is rewards goes before cash)
              this.autopayToggle = true;
              this.toggleText = 'On';
              this.autopayOptions = [
                {key: 'rewards', checked: data?.isRewardsSelected, disabled: false},
                {key: 'cash', checked: data?.isCashSelected, disabled: false},
                {key: 'default', checked: true, disabled: true},
              ];  
            }
            if (this.isAutoTransferOn) {
              const filteredArray = this.autopayOptions.filter(obj => obj.key !== 'cash');
              this.autopayOptions = filteredArray;
            }
          } else {
            // Autosequence didn't return anything, display error message.
            this.loading = false;
            this.disableButton = true;
            this.systemError = true;
          }
        }, error => {
          this.loading = false;
          this.disableButton = true;
          this.systemError = true;
        }
      );
    }
    
  }

  anyOptionIsSelected(item: any) {
    return item.checked;
  }

  onChange(event: any, item: any) {
    item.checked = event?.target?.checked; // Everytime a checkbox is checked or unchecked, save the selection in autopayOptions

    // If any of the 3 options are now in the checked state, make sure to turn on the autopay toggle
    if (this.autopayOptions.find(this.anyOptionIsSelected)) {
      this.autopayToggle = true;
      this.toggleText = 'On'
      // When autopay is toggled on, we MUST check the default payment method option for the user. They cannot unselect this.
      for (let i = 0; i < this.autopayOptions.length; i++) {
        if (this.autopayOptions[i].key == 'default') {
          this.autopayOptions[i].checked = true;
        }
      }
    } else {
      // Technically this part of the code shouldn't be reachable, as the user can never manually unselect the default payment method,
      // but leaving the code here as a safeguard.
      this.autopayToggle = false;
      this.toggleText = 'Off';
      for (let i = 0; i < this.autopayOptions.length; i++) {
        this.autopayOptions[i].checked = false;
      }
    }
  }

  // If the autopay toggle is toggled on or off, check/uncheck all 3 autopay options
  onToggleChange(event: any) {
    this.toggleText = this.autopayToggle ? 'On' : 'Off'; // autopayToggle variabe is automatically updated via ngModel
    
    // Update all 3 options to checked or unchecked based on the new autopayToggle value
    for (let i = 0; i < this.autopayOptions.length; i++) {
      this.autopayOptions[i].checked = this.autopayToggle;
    }
  }

  // Both of these indexes will return -1 when not true (aka the option was not checked)
  // And return 0 or 1 if they are checked, depending on where it is in the array
  rewardsIndex(item: any) {
    return item.key == 'rewards' && item.checked == true;
  }

  cashIndex(item: any) {
    return item.key == 'cash' && item.checked == true;
  }
  
  openAutoRedeemDialog() {
    this.successMessage = false;
    this.storeInitialState();

    let defaultToken = this.walletDetails?.token?.replace(/^"(.*)"$/, '$1');
    this.currentSelection = {
      walletID: this.walletDetails?.walletID,
      walletRole: this.ONLSessionDetails?.navigatingSystemRole,
      policyNo: this.ONLSessionDetails?.productSystemIdentifier1,
      isAutoPay: this.autopayToggle,
      rewardsSequence: "",
      cashSequence: "",
      defaultPaymentMethodToken: this.autopayToggle ? defaultToken : undefined,
      isCashSelected: "",
      isRewardsSelected: "",
      pcAccountNo: this.ONLSessionDetails?.productSystemIdentifier2,
      productSystem: this.ONLSessionDetails?.productSystem,
    }

   // console.log("Toggle Output: ", this.autopayToggle);

    if (!this.autopayToggle) {
      // Autopay was turned off. None of the 3 options were selected
      this.currentSelection.rewardsSequence = undefined;
      this.currentSelection.cashSequence = undefined;
      this.currentSelection.isRewardsSelected = false;
      this.currentSelection.isCashSelected = false;
    } else {
      let currentRewardsIndex = this.autopayOptions.findIndex(this.rewardsIndex); // Index returning -1 means item was not checked. 0 or 1 signifies the sequence, if checked
      let currentCashIndex = this.autopayOptions.findIndex(this.cashIndex);
      this.currentSelection.isRewardsSelected = currentRewardsIndex > -1 ? true : false;
      this.currentSelection.isCashSelected = currentCashIndex > -1 ? true : false;

      if (currentRewardsIndex > -1 && currentCashIndex == -1) {
        // Scenario: Rewards is selected, Cash is not. Regardless of if rewards index is currently 0 or 1, make sure we pass it with sequence = 1
        this.currentSelection.rewardsSequence = "1";
        this.currentSelection.cashSequence = "0";
      } else if (currentRewardsIndex == -1 && currentCashIndex > -1) {
        // Scenario: Cash is selected, Rewards is not. Regardless of if cash index is currently 0 or 1, make sure we pass it with sequence = 1
        this.currentSelection.rewardsSequence = "0";
        this.currentSelection.cashSequence = "1";
      } else if (currentRewardsIndex > -1 && currentCashIndex > -1) {
        // Both options are selected. List them in the order in which they were selected.
        this.currentSelection.rewardsSequence = (currentRewardsIndex + 1).toString(); //Index returns 0 and 1 but our API needs us to send 1 and 2 as part of the sequence, hence the +1.
        this.currentSelection.cashSequence = (currentCashIndex + 1).toString();
      } else {
        // Neither option is current selected. Pass both as 0 for sequence
        this.currentSelection.rewardsSequence = "0";
        this.currentSelection.cashSequence = "0";
      }
    }

    if (this.autopayToggle) {
      // If toggle is on, directly confirm the update
       this.processAndOpenFinalDialog();
    } else {
      // Otherwise, open the dialog for confirmation
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.data = {
        currentSelection: this.currentSelection,
        autopayOptions: this.autopayOptions,
        defaultMethod: {
          cardType: this.cardType,
          lastFourDigits: this.lastFourDigits,
          ccExpiration: this.ccExpiration,
        },
        isAutoPay: this.autopayToggle,
        skipFirstStep: this.autopayToggle, // Add this flag to data
      };
      let dialogRef = this.dialog.open(AutoRedeemDialogComponent, config);
      dialogRef.componentInstance.autopayResult.subscribe(result => {
        if (result === true) {
          this.successMessage = true;
          this.updateWalletSummary();
        } else if (result === 'restore') {
          this.restoreInitialState();
        } else if (result === 'cancel') {
          this.loadAutoPayOptions();
        } else {
          this.successMessage = false;
          this.restoreInitialState();
        }
      });
    }
  }

  storeInitialState() {
    this.initialState = {
      autopayToggle: this.autopayToggle,
      autopayOptions: JSON.parse(JSON.stringify(this.autopayOptions)), // Deep copy
    };
  }

  restoreInitialState() {
    this.autopayToggle = this.initialState.autopayToggle;
    this.autopayOptions = JSON.parse(JSON.stringify(this.initialState.autopayOptions)); // Deep copy
  //  console.log("Restore Initial State: ", this.autopayOptions)
  }

  processAndOpenFinalDialog() {
    this.buttonLoading = true;
    this.getAutoPaySequence.setAutoPay(this.currentSelection).subscribe(
      data => {
        this.buttonLoading = false;
        if (data.success) {
          this.successMessage = true;
          this.updateWalletSummary();
          let config = new MatDialogConfig();
          config.autoFocus = false;
          config.data = {
            currentSelection: this.currentSelection,
            autopayOptions: this.autopayOptions,
            defaultMethod: {
              cardType: this.cardType,
              lastFourDigits: this.lastFourDigits,
              ccExpiration: this.ccExpiration,
            },
            isAutoPay: this.autopayToggle,
            skipFirstStep: true, // Ensure this is set to true
          };
  
          let dialogRef = this.dialog.open(AutoRedeemDialogComponent, config);
          dialogRef.componentInstance.currentStep = 1; // Set to the final step directly
          dialogRef.componentInstance.autopayResult.subscribe(result => {
            if (result) {
              this.successMessage = true;
              this.updateWalletSummary();
            } else {
             // this.successMessage = false;
            }
          });
        } else {
          this.systemError = true;
        }
      }, error => {
        this.buttonLoading = false;
        this.systemError = true;
      }
    );
  }

  openSetDefaultPaymentModal(): void {
    if (!this.modalOpened) { // Check if the modal has already been opened
      this.modalOpened = true; // Set the flag to true
      const dialogRef = this.dialog.open(ModalDefaultPaymentComponent, {
        width: '600px', // Set the width of the modal
        data: { /* Pass any data you need to the modal here */ }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.modalOpened = false; // Reset the flag when the modal is closed
        console.log('The dialog was closed');
        // Handle the result if needed
      });
    }
  }

  // openSetDefaultPaymentModal() {
  //   let config = new MatDialogConfig();
  //   config.autoFocus = false;
  //   config.data = "setting up Auto Redeem & Pay";
  //   let dialogRef = this.dialog.open(ModalDefaultPaymentComponent, config);
  // }

  gotoSetDefaultPayment(){
    this.router.navigate(['/preferences/manage-payment'], { relativeTo:this.route });
  }

  updateWalletSummary() {
    let request = {
      sessionID:sessionStorage.getItem("SessionID"),
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!)
    }
    this.userService.getWalletSummary(request).subscribe(
      data => {
        // Set updated wallet summary details now that the user has enrolled or unenrolled in autopay.
        if (data?.wallet) {
          sessionStorage.setItem('walletDetails', JSON.stringify(data.wallet));
        }
      }
    );
  }

  checkCCExpiration(ccExpiration, type) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
    const expirationYear = parseInt(ccExpiration?.slice(0, 4));
    const expirationMonth = parseInt(ccExpiration?.slice(4, 6));

    let isExpired;
    let isWarning;

    if (currentYear > expirationYear) {
      // Card is expired if current year is greater than expiration year
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth > expirationMonth) {
      // Card is expired if current year is equal to expiration year and current month is greater than expiration month
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth === expirationMonth) {
      const daysInMonth = this.daysInMonth(currentMonth, currentYear);
      const daysUntilExpiration = daysInMonth - currentDate.getDate();
      // Card technically does not expire until the end of the month, but if there are 5 or fewer days until expiration
      // Disallow them from using the payment method - so we set it as 'warning' and not 'expired' but user not allowed to use the payment method in either case.
      if (daysUntilExpiration <= 5) {
        isExpired = false;
        isWarning = true;
      } else {
        // There are still more than 5 days in the month before card expires, let them use the payment method.
        isExpired = false;
        isWarning = false;
      }
    } else {
      // Card is not expired
      isExpired = false;
      isWarning = false;
    }

    if (type == 'expired') {
      return isExpired;
    } else if (type == 'warning') {
      return isWarning;
    }
  }

  daysInMonth(m, y) {
    // month is 0-based: Jan = 0, Dec = 11
    // (m-1 ? Not February : February) checks if month is Feb
    // y & (y % 25 ? 3 : 15) is falsy for leap years, hence 31-2 days in Feb- otherwise, it's 31-3 days
    // m % 7 makes it so m is even for months with 31 days, odd for rest
    // subtracting lowest bit &1 results in 31-1 days for April/June/Sept/Nov, 31-0 for the rest
    // referenced stackexchange post for this function
    return 31 - (m - 1 ? m % 7 & 1 : y & (y % 25 ? 3 : 15) ? 3 : 2);
  }
}
