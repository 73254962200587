// src/app/services/get-wallet-notification.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndpoints } from 'src/config/api-endpoints';

@Injectable({
  providedIn: 'root'
})


export class GetWalletNotificationService {

  private apiURL = ApiEndpoints.appNotification;

  //  private apiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/dwgetwalletnotificationinapp';

    constructor(private http: HttpClient) {}

    fetchWalletNotifications(walletID: string, walletRole: string): Observable<any> {
      let wallettoken=sessionStorage.getItem("walletheaders");
      let sessionID=sessionStorage.getItem("SessionID");
      let httpOptions = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET",
          "Authorization": "Bearer " + wallettoken
        }),
      }; 
        return this.http.post<any>(this.apiURL, {walletID, walletRole,sessionID}, httpOptions); // Empty object as body   
    }

    setWalletNotifications(data:any): Observable<any> {
      let wallettoken=sessionStorage.getItem("walletheaders");


      let httpOptions = {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET",
          "Authorization": "Bearer " + wallettoken
        }),
      }; 
      
      const setNotificationURL = ApiEndpoints.setAppNotification;
        return this.http.post<any>(setNotificationURL, data, httpOptions);
    }

}