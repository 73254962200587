
<div class="sub-container">
    <h2 class="headline-highlight">My Rewards</h2>

    <div class="balance-container">     
      
        <div class="balance-details">

            <div class="wallet-details">    
                <div class="balance-blocks">
                    <div class="title">BALANCE</div>
                        <div class="content">
                            <span class="svg"><img src="../../../../assets/icons/star_rate.svg"></span>
                            <span class="value">{{ walletSummary?.wallet?.rewards?.totalRewardBalance ?? 0 | number }}</span>
                            <span class="pts"> PTS</span>
                        </div>
                    <div class="more">{{ walletSummary?.wallet?.rewards?.totalRewardValue ?? 0 | currency}} Value</div>
                </div>       
            </div>
   
            <div class="wallet-details">    
                <div class="button-container">
                    <div class="btn" *ngIf="dataLoaded && !((cashEligible && !rewardEligible) || (autoPay === 1))">
                        <button (click)="goToRedeem()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                            <span class="mdc-button__label">
                                <span class="button-label">
                                    <span>Redeem</span>
                                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon> 
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            </div>
        </div>  
        
        <h2 class="headline-highlight">Transaction History</h2>   
        
        <div class="search-box-container box-border">
        
            <div class="search-container">
              <mat-form-field class="custom-placeholder">
            <mat-icon matPrefix>search</mat-icon>
              <input class="search-box" matInput placeholder="Search Table" [(ngModel)]="searchValue" (keyup.enter)="applySearch(searchValue)">
            </mat-form-field>
                    
            <div class="button-container">
                <div class="btn">
                    <button (click)="applySearch(searchValue)" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                        <span class="mdc-button__label">
                            <span class="button-label">
                                <span>Search</span>
                                <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon> 
                            </span>
                        </span>
                    </button>
                </div>
            </div>      
        
        </div>
          
        <div class="radio-buttons">
            <mat-radio-group [(ngModel)]="rewardType" (change)="fetchRewards()">
              <mat-radio-button value="earned" [disableRipple]="true" >Rewards Earned</mat-radio-button>
              <mat-radio-button value="redeemed" [disableRipple]="true">Rewards Redeemed</mat-radio-button>
            </mat-radio-group>
          </div>
       
        </div>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="dw-header-cell" >
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            disableRipple>
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            disableRipple>
              </mat-checkbox>
            </td>
          </ng-container>

      <!-- Earn Columns -->
      <ng-container matColumnDef="earnDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header" >Date</th>
        <td mat-cell *matCellDef="let element">{{ element.earnDate | date : "MM/dd/YYYY"}}</td>
      </ng-container>
  
      <ng-container matColumnDef="pointsEarned">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.pointsEarned | number }} pts</td>
      </ng-container>
  
      <ng-container matColumnDef="earnCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Funds Type</th>
        <!-- <td mat-cell *matCellDef="let element">{{ element.earnCategory }}</td> -->
        <td mat-cell *matCellDef="let element">Rewards</td>
      </ng-container>
  
      <!-- <ng-container matColumnDef="earnIdentifierDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell">Details</th>
        <td mat-cell *matCellDef="let element">{{ element.earnIdentifierDescription }}</td>
      </ng-container> -->

      <ng-container matColumnDef="earnCategoryDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Details</th>
        <td mat-cell *matCellDef="let element">{{ element.earnCategoryDescription }}</td>
      </ng-container>
  
      <!-- Redeem Columns -->
      <ng-container matColumnDef="spendDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Date</th>
        <td mat-cell *matCellDef="let element">{{ element.spendDate | date : "MM/dd/YYYY" }}</td>
      </ng-container>
  
      <ng-container matColumnDef="pointSpend">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.pointSpend | number }} pts</td>
      </ng-container>

      <ng-container matColumnDef="spendCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Funds Type</th>
        <!-- <td mat-cell *matCellDef="let element">{{ element.spendCategory }}</td> -->
        <td mat-cell *matCellDef="let element">Rewards</td>
      </ng-container>
  
      <ng-container matColumnDef="spendIdentifierDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Reference Number</th>
        <td mat-cell *matCellDef="let element">INV. {{ element.spendIdentifier }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Status</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.status=='COMPLETED'"class="status_completed">
          <div class="status_completed_txt">{{ element.status }}</div>
        </div>
        <div *ngIf="element.status=='INPROGRESS'|| element.status=='PROCESSING'"class="status_inprogress">
          <div class="status_inprogress_txt">{{ element.status }}</div>
        </div>
        <div *ngIf="element.status=='FAILED'"class="status_failed">
          <div class="status_failed_txt">{{ element.status }}</div>
        </div></td>
      </ng-container> -->
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons  class="custom-paginator"></mat-paginator>
  </div>
  

</div>

