export const environment = {
  production: false,
  isUat: true,
  baseURL:{
    urlType: 'uat'
  },

  dpatAPIUrl: `https://dpatinside-api-vulture.upscapital.com`,
//  walletAPIUrl:`https://ib-wallet-euat-api.azurewebsites.net`,
//  walletAPPUrl:`https://ib-wallet-euat-app.azurewebsites.net`,

walletAPIUrl:`https://wallet-svcs-vulture.ups.com`,
walletAPPUrl:`https://wallet-vulture.ups.com`,

  loginUrl: 'https://online-vulture.upscapital.com/login',
  createAccount: 'https://online-vulture.upscapital.com/create-account',

  get baseApiUrl() {
    return `${this.dpatAPIUrl}/api/`;
  },
  get walletbaseApiUrl() {
    return `${this.walletAPIUrl}/api/`;
  },
  walletsecret:'d64ab524-a4f2-47ca-afc4-ff7f6527e37c',
  apiUrls: {
   // session: "Authentication/ONLValidateWalletSession?sessionId=",
    sessionjwt: "Authentication/ONLValidateWalletSessionJWT",
    profile: 'DigitalWalletAPI',

    walletSummary: 'DigitalWalletAPI/dwwalletsummary',
    walletSetup: 'DigitalWallet/DWWalletSetup',

    transactionHistory: 'DigitalWalletAPI/dwgettransactionhistory',
    sourceIdentifier: 'DigitalWalletAPI/dwgetsourceidentifierdetails',
    invoiceDetails: 'DigitalWalletAPI/dwgetinvoicedetails',

    getAutoPaySequence: 'DigitalWalletAPI/dwgetautopaysequence',
    setAutoPay: 'DigitalWallet/DWSetAutoPay',

    redeemEarn: 'DigitalWalletAPI/redeemrewards',
    cashTransactionApiUrl: "DigitalWalletAPI/cashtransaction",
    invoicepaymentApiUrl: "DigitalWallet/DWMakeInvoicePayment",
    
    startWalletSession: 'Authentication/DWStartWalletSession',

    dwTokenInfo: 'DigitalWallet/DWGetTokenInfo',
    setdefaultpaymentUrl: 'DigitalWallet/DWSetDefaultPaymentMethod',
    deletepaymentUrl: 'DigitalWallet/DWDeletePaymentMethod',

    dwPayoutTokenInfo:'DigitalWalletPayee/DWGetPayoutTokens',
    dwDeletePayoutToken:'DigitalWalletPayee/DWDeletePayoutToken',
    initiateCashTransfer: 'DigitalWalletAPI/dwinitiatecashtransfer',

    appNotification: 'DigitalWalletAPI/dwgetwalletnotificationinapp',
    setAppNotification: 'DigitalWalletAPI/dwsetwalletnotificationinapp'

  }
};
  