import { environment } from "../environments/environment";

export const ApiEndpoints = {

  userIn:'Vampire#1',
  passwordIn:'#1Vampire',
  secret:`${environment.walletsecret}`,
  
   // session: `${environment.walletbaseApiUrl}${environment.apiUrls.session}`,
    sessionjwt: `${environment.walletbaseApiUrl}${environment.apiUrls.sessionjwt}`,
    profile: `${environment.walletbaseApiUrl}${environment.apiUrls.profile}`,

    walletSummary: `${environment.walletbaseApiUrl}${environment.apiUrls.walletSummary}`, 
    walletSetup: `${environment.baseApiUrl}${environment.apiUrls.walletSetup}`,

    transactionHistory: `${environment.walletbaseApiUrl}${environment.apiUrls.transactionHistory}`,
    sourceIdentifier: `${environment.walletbaseApiUrl}${environment.apiUrls.sourceIdentifier}`,
    invoicedetails: `${environment.walletbaseApiUrl}${environment.apiUrls.invoiceDetails}`,

    getAutoPaySequence: `${environment.walletbaseApiUrl}${environment.apiUrls.getAutoPaySequence}`,
    setAutoPay: `${environment.baseApiUrl}${environment.apiUrls.setAutoPay}`,

    redeemEarn: `${environment.walletbaseApiUrl}${environment.apiUrls.redeemEarn}`,
    cashTransactionApiUrl: `${environment.walletbaseApiUrl}${environment.apiUrls.cashTransactionApiUrl}`,
    invoicepaymentApiUrl: `${environment.baseApiUrl}${environment.apiUrls.invoicepaymentApiUrl}`,
  
    startWalletSession: `${environment.baseApiUrl}${environment.apiUrls.startWalletSession}`,
    startWalletSessionCallBack: `${environment.walletAPPUrl}`,
  
    dwTokenInfo: `${environment.baseApiUrl}${environment.apiUrls.dwTokenInfo}`,
    setdefaultpaymentUrl: `${environment.baseApiUrl}${environment.apiUrls.setdefaultpaymentUrl}`,
    deletepaymentUrl: `${environment.baseApiUrl}${environment.apiUrls.deletepaymentUrl}`,

    dwPayoutTokenInfo:`${environment.baseApiUrl}${environment.apiUrls.dwPayoutTokenInfo}`,
    dwDeletePayoutToken:`${environment.baseApiUrl}${environment.apiUrls.dwDeletePayoutToken}`,
    initiateCashTransfer: `${environment.walletbaseApiUrl}${environment.apiUrls.initiateCashTransfer}`, 

    appNotification: `${environment.walletbaseApiUrl}${environment.apiUrls.appNotification}`, 
    setAppNotification: `${environment.walletbaseApiUrl}${environment.apiUrls.setAppNotification}`,

    // Hanson's work below - going to be trying to copy how ONL currently implements and calls APIs
    // May look a bit different to how you guys are currently doing it!
    getWalletSummary: `DigitalWalletAPI/dwwalletsummary`, // Putting this call in user.service.ts
    getAutoPaySequence2: `DigitalWalletAPI/dwgetautopaysequence`,
    setautotransfer: `${environment.walletbaseApiUrl}DigitalWalletAPI/dwsetwalletautotransfer`,
  };