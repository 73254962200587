<!-- flyout.component.html -->

<div class="flyout">

  <button class="close" (click)="closeFlyout()">&#x2715;</button>
  <div *ngIf="loading" class="loading-container">
    <div class="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
  <div class="flyout-container" *ngIf="!loading">
    <div style="display:inline;">
      <h2 class="headline-highlight underline">Auto Transfer</h2>
      <span class="mat-toggle labeltxt ml-auto togglesetting">
        <label class="labeltxt toggletxt">Off &nbsp;</label>
        <mat-slide-toggle [(ngModel)]="autoTransferToggle" (change)="onToggleChange($event)"></mat-slide-toggle>

        <label class="labeltxt toggletxt">On</label>
      </span>
    </div>
    <div id="auto-content">
      <p class="account-label">Auto Transfer will be processed when claims payments are added to your wallet, with the
        transfer amount matching the claims payment.</p>
      <p class="account-type">Transfer Type</p>
      <div class="tokendiv">
        <input type="radio" class="ups-teal form-check-input" [(ngModel)]="isinstant" value="0"
          (click)="reloadpaymethods('instant')" id="0" />
        <div class="iconbox"><img class="pay-icon" src=".//../../../../assets/icons/electric_bolt.png">
        </div>
        <div class="pay-info">
          <span>Instant (1.5 % service fee)</span>
        </div>
      </div>
      <hr class="divider" />
      <div class="tokendiv">
        <input type="radio" class="ups-teal form-check-input" [(ngModel)]="isinstant" value="1"
          (click)="reloadpaymethods('non-instant')" id="1" />
        <div class="iconbox"><img class="pay-icon" src="./../../../../assets/icons/account_balance.png">
        </div>
        <div class="pay-info">
          <span>1-3 Business days (No Service fee)</span>
        </div>
      </div>




      <p class="account-type">Transfer To</p>
      <div *ngIf="!nopayments">
        <div *ngFor="let ectoken of payoutaccounts">
          <div class="tokendiv">
            <input type="radio" class="ups-teal form-check-input" [(ngModel)]="selectedtoken" 
              value="{{ectoken.payeeID}}" id="{{ectoken.payeeID}}" />
            <div class="iconbox"><img class="pay-icon" src="{{ectoken.cardImg}}">
            </div>
            <div class="pay-info" *ngIf="ectoken.achIndicator =='T'">

              <span>{{ectoken.counterpartyPayOrg}}&nbsp;{{ectoken.counterPartyBankAccountCategory}}&nbsp;{{ectoken.counterPartyBankAccountType}}</span>
              <span>Bank **** {{ectoken.counterPartyAccountNumber}}</span>
            </div>
            <div class="pay-info" *ngIf="ectoken.zelleIndicator =='T'">
              <span>ZELLE</span>
              <span>{{ectoken.counterPartyAccountNumber}}</span>
            </div>

          </div>
          <div *ngIf="!ectoken.rtpEligible && ectoken.achIndicator =='T'" class="style_italic">
            *This bank does not support instant transfer
          </div>
          <div *ngIf="!ectoken.isLastOption">
            <hr>
          </div>

        </div>

      </div>
    
    <div *ngIf="NoAccountsAT" class="at-noaccounts">
      <img src="../../../../assets/icons/warning.png">
          <span name="errorMessage" class="ml-10">{{ATValidationMsg}}</span>
          <br>
  </div>
    <div *ngIf="!loading" class="add-payment" (click)="addPaymentMethod()">
      <img src="../../../../assets/icons/add.svg"><a>Add New Transfer Account</a>
    </div>
    
  </div>
    <br>
    <div class="">
      <button *ngIf="!holdbtn && !NoAccountsAT" (click)="UpdateAutoTransfer()" id="transferbtn"
        class="btn-balance dw-primary-button">
        <span class="mdc-button__label">
          <span class="button-label">
            <span>Save Changes</span>
            <mat-icon
              class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </span>
        </span>
      </button>
      <button *ngIf="holdbtn && !NoAccountsAT" disabled (click)="UpdateAutoTransfer()" class="btn-balance dw-primary-button">
        <span class="mdc-button__label">
          <span class="button-label">
            <span>Save Changes</span>
            &nbsp;&nbsp;<span class="spinner-border"></span>
          </span>
        </span>
      </button>
      <button *ngIf="NoAccountsAT" disabled (click)="UpdateAutoTransfer()" class="btn-balance dw-primary-button">
        <span class="mdc-button__label">
          <span class="button-label">
            <span>Save Changes</span>
            <mat-icon
              class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </span>
        </span>
      </button>
    </div>
</div>

</div>