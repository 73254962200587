import { Component, Renderer2, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { TransferFlyoutComponent } from '../transfer-flyout/transfer-flyout.component';
import { PayoutTokensService } from 'src/app/shared/services/api/get-payout-tokens.service';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { BankDetails, DWPayoutToken, PayoutTokens, PayoutTokensResponse, transferCarrier } from 'src/app/shared/models/payouttokens.model';
import { CurrencyPipe, DatePipe, formatCurrency } from '@angular/common';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DynamicPopupComponent } from 'src/app/shared/components/dynamic-popup/dynamic-popup';
import { AutoTransfer, DWSetAutoTransferRequest, DWSetAutoTransferResponse } from 'src/app/shared/models/AutoTransfer.model';
import { TransferPopUpComponent } from '../transfer-pop-up/transfer-pop-up.component';
import { AutoTransferService } from 'src/app/shared/services/api/auto-transfer.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-transfer-balance',
  templateUrl: './transfer-balance.component.html',
  styleUrls: ['./transfer-balance.component.scss']
})
export class TransferBalanceComponent implements OnInit, OnDestroy {
  [x: string]: any;
  @ViewChild('slide')
  matSlideToggle: MatSlideToggle;

  currentBalance: string = '';
  cashBalance: string = '';
  amount: string = '';
  paymenttokens: DWPayoutToken[] = [];
  payoutaccounts: any;
  selectedtoken: string = '';
  showzelle: boolean = false;
  noninstantdiv: any;
  instantdiv: any;
  errorMessage: any = '';
  validationMessage: any = '';

  walletSummary: WalletSummary | null = null;
  walletId: string = '';
  walletRole: string = '';
  autoPay: number = 0;
  autoTransfer: boolean;
  dwdpatsession: any;
  nopayments: boolean = true;

  //@ViewChild(TransferFlyoutComponent, { static: true }) transferFlyoutComponent!: TransferFlyoutComponent;
  walletDetails: any;
  isLastOption: boolean = false;
  rewardsBalance: string = '';
  loading: boolean = true;
  holdbtn: boolean = false;
  lastTransferType: string = '';

  showBanner: boolean = true;
  autoTransferToggle: boolean;
  toggleText: string = 'Off'; 
  NoAccountsAT :boolean;
  ATValidationMsg:string='';
  

  constructor(public sessionservice: GetGuidService, public route: Router, private renderer: Renderer2, private el: ElementRef, public payouttokens: PayoutTokensService, public routeActivate: ActivatedRoute, public walletSummaryService: WalletSummaryService, public dialog: MatDialog,
    public autotransferservice:AutoTransferService) {

  }


  ngAfterContentInit() {
    this.getpayouttokens();

  }

  ngDoCheck()
  {
   // this.updateBalance();
  }
  ngAfterViewChecked() {
    this.noninstantdiv = document.getElementById('non-instant');
    this.instantdiv = document.getElementById('instant');
    this.instantdiv?.classList.add('selected');
    this.noninstantdiv?.classList.remove('selected');
    if (this.lastTransferType == 'NonRTP') {
      this.noninstantdiv?.classList.add('selected');
      this.instantdiv?.classList.remove('selected');
    }
    else {
      this.instantdiv?.classList.add('selected');
      this.noninstantdiv?.classList.remove('selected');
    }
    this.updateBalance();
  }

  removeAlpha() {
    this.amount = this.amount.replace(/[a-zA-Z~@#^*()_+=[\]{}|\\,?:; -!%&`/"'<>|]/, '');
  }


  // ngOnInit(): void {
  //   // Check Auto-Transfer status from sessionStorage
  //   const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');

  //   console.log("Const AutoTrasnferData:", autoTransferData);
  //   if (autoTransferData && autoTransferData.isAutoTransfer) {
  //     this.autoTransferToggle = true;
  //     this.selectedtoken = autoTransferData.defaultToken;\]
  //     this.lastTransferType = autoTransferData.transferType;
  //   } else {
  //     this.autoTransferToggle = false;
  //   }
  
  //   console.log("Transfer Balance Auto Transfer Toggle State: ", this.autoTransferToggle);

  //     // Retrieve wallet details from sessionStorage
  // this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
  // if (this.walletDetails) {
  //   this.cashBalance = parseFloat(this.walletDetails.cash.totalCashBalance.toString()).toFixed(2);
  //   this.currentBalance = parseFloat(this.cashBalance.toString()).toFixed(2);
  //   this.amount = formatCurrency(
  //     parseFloat(parseFloat(this.currentBalance).toFixed(2)),
  //     'en-us',
  //     '$'
  //   );

  //     // Extract and set auto-transfer details
  //     const autoTransferDetails = this.walletDetails.autotransfer || {};
  //     this.autoTransferToggle = autoTransferDetails.isDWAutoTransfer === 'True';
  //     this.selectedtoken = autoTransferDetails.payeeID || '';
  //     this.lastTransferType = autoTransferDetails.autoTransferType || 'RTP';

  //      // Update sessionStorage with the correct AutoTransfer values
  //   sessionStorage.setItem(
  //     'AutoTransfer',
  //     JSON.stringify({
  //       isAutoTransfer: this.autoTransferToggle,
  //       transferType: this.lastTransferType,
  //       defaultToken: this.selectedtoken
  //     })
  //   );
  // } else {
  //   console.warn("No wallet details found in sessionStorage.");
  // }
  


  
  //   // Subscribe to wallet summary service
  //   this.walletSummaryService.walletSummary$.subscribe(summary => {
  //     this.walletSummary = summary;
  
  //     // Parse and assign wallet details
  //     this.currentBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
  //     this.cashBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
  //     this.amount = formatCurrency(
  //       parseFloat(parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2)),
  //       'en-us',
  //       '$'
  //     );
  //     this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
  //     this.autoTransferToggle = summary?.wallet?.isDWAutoTransfer === 'True' || false;
  //     console.log('Auto Transfer state on load:', this.autoTransferToggle);
  //     this.rewardsBalance = parseFloat(summary?.wallet?.rewards?.totalRewardBalance).toFixed(2);
  
  //     console.log("AutoPay from Transfer Balance: ", this.autoPay);
  //   });

  //   // this.autoTransferToggle = this.walletSummary?.wallet?.isDWAutoTransfer === 'True';
  //   // this.selectedtoken = this.walletSummary?.wallet?.autotransfer?.payeeID || '';
  //   // this.lastTransferType = this.walletSummary?.wallet?.autotransfer?.autoTransferType || 'RTP';

  //   // console.log('Auto Transfer state from API:', this.autoTransferToggle);

  //   // sessionStorage.setItem(
  //   //   'AutoTransfer',
  //   //   JSON.stringify({
  //   //     isAutoTransfer: this.autoTransferToggle, // Derived from `autotransfer.isDWAutoTransfer`
  //   //     transferType: this.walletSummary?.wallet?.autotransfer?.autoTransferType || '',
  //   //     defaultToken: this.walletSummary?.wallet.autotransfer?.payeeID || ''
  //   //   })
  //   // );
    
  // }
  
  ngOnInit(): void {
    this.updateBalance();
    // Check Auto-Transfer status from sessionStorage
    const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');
    console.log("Const AutoTransferData:", autoTransferData);
    if (autoTransferData && autoTransferData.isAutoTransfer!=undefined&& autoTransferData.isAutoTransfer.toString().toLowerCase()=="true"?true:false){
      this.route.navigate(['my-wallet', 'my-cash', 'auto-transfer']);
      this.autoTransferToggle = true;
      this.selectedtoken = autoTransferData.defaultToken=="0"?"":autoTransferData.defaultToken;
      this.lastTransferType = autoTransferData.transferType;
    } else {
      this.autoTransferToggle = false;
      
    }

    console.log("Transfer Balance Auto Transfer Toggle State: ", this.autoTransferToggle);

    // Retrieve wallet details from sessionStorage
    this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
    
    if (this.walletDetails) {
      
      this.cashBalance = parseFloat(this.walletDetails.cash.totalCashBalance.toString()).toFixed(2);
      this.currentBalance = parseFloat(this.cashBalance.toString()).toFixed(2);
      this.amount = formatCurrency(
        parseFloat(parseFloat(this.currentBalance).toFixed(2)),
        'en-us',
        '$'
      );

      // Extract and set auto-transfer details
      const autoTransferDetails = this.walletDetails.autotransfer || {};
      this.autoTransferToggle = autoTransferDetails.isDWAutoTransfer === 'True';
      this.selectedtoken = autoTransferDetails.payeeID || '';
      this.lastTransferType = autoTransferDetails.autoTransferType || 'RTP';

      // Update sessionStorage with the correct AutoTransfer values
      
    } else {
      console.warn("No wallet details found in sessionStorage.");
    }

    // Subscribe to wallet summary service
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if(summary!=null){
      this.walletSummary = summary;

      // Parse and assign wallet details
      this.currentBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
      this.cashBalance = parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2);
      this.amount = formatCurrency(
        parseFloat(parseFloat(summary?.wallet?.cash?.totalCashBalance).toFixed(2)),
        'en-us',
        '$'
      );
      this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
      this.autoTransferToggle = summary?.wallet?.isDWAutoTransfer === 'True' || false;
      console.log('Auto Transfer state on load:', this.autoTransferToggle);
      this.rewardsBalance = parseFloat(summary?.wallet?.rewards?.totalRewardBalance).toFixed(2);

      console.log("AutoPay from Transfer Balance: ", this.autoPay);
      }
    });

    this.autoTransferToggle = this.walletSummary?.wallet?.isDWAutoTransfer === 'True';
    this.selectedtoken = this.walletSummary?.wallet?.autotransfer?.payeeID || '';
    this.lastTransferType = this.walletSummary?.wallet?.autotransfer?.autoTransferType || 'RTP';
    console.log('Auto Transfer state from API:', this.autoTransferToggle);
    /* sessionStorage.setItem(
      'AutoTransfer',
      JSON.stringify({
        isAutoTransfer: this.autoTransferToggle,
        transferType: this.lastTransferType,
        defaultToken: this.selectedtoken
      })
    ); */
    
  }

  // Method to handle changes in payeeID and isAutoTransfer
  updateAutoTransferValues(): void {
    sessionStorage.setItem(
      'AutoTransfer',
      JSON.stringify({
        isAutoTransfer: this.autoTransferToggle,
        transferType: this.lastTransferType,
        defaultToken: this.selectedtoken
      })
    );
  }

  // Call this method whenever the user changes the payeeID or isAutoTransfer values
  onPayeeIDChange(newPayeeID: string): void {
    this.selectedtoken = newPayeeID;
    this.updateAutoTransferValues();
  }

  onAutoTransferToggleChange(newStatus: boolean): void {
    this.autoTransferToggle = newStatus;
    this.updateAutoTransferValues();
  }

  getpayouttokens() {
    let payouttokens: PayoutTokens = new PayoutTokens();
    let transferType: any;
    if (document.getElementById('instant') == null) {
      transferType = 'RTP';
      this.lastTransferType = 'RTP';
    }
    else if (document.getElementById('instant')?.classList.contains('selected')) {
      transferType = 'RTP';
      this.lastTransferType = 'RTP';
    }
    else {
      transferType = 'NonRTP';
      this.lastTransferType = 'NonRTP';
    }
    payouttokens.transferType = transferType;
    payouttokens.walletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    payouttokens.walletRole = JSON.parse(sessionStorage.getItem("WalletRole") || '{}');


    let listoftokens = this.payouttokens.fetchpayouttokens(payouttokens).subscribe({
      next: (data: PayoutTokensResponse) => {

        this.nopayments = true;
        if (data.success == true)
          this.nopayments = false;
        this.paymenttokens = data.payoutTokens;
        this.paymenttokens.forEach((token: any) => {

          if (token.zelleIndicator == 'T') {
            token.cardImg = this.payouttokens.getcardImage('zelle');

          }
          else {
            token.counterpartyPayOrg = token.counterpartyPayOrg.replace('NA', '');
            token.cardImg = this.payouttokens.getcardImage(token.counterpartyPayOrg);
            if (token.counterPartyAccountNumber != null && token.counterPartyAccountNumber != undefined) {
              let accnumber = token.counterPartyAccountNumber;
              token.counterPartyAccountNumber = accnumber.substring(accnumber.length - 4, accnumber.length + 1);
            }
            else {
              token.counterPartyAccountNumber = '0000';
            }
          }
          /*  let expiration=token.ccexpiration;
           token.ccexpiration=expiration.slice(4,6)+"/"+ expiration.slice(2,4) */
        });

        this.instantaccounts = this.paymenttokens.filter((x: any) => x.achIndicator == 'T');
        this.zelleaccounts = this.paymenttokens.filter((x: any) => x.achIndicator == 'F');
        this.payoutaccounts = this.paymenttokens;
        if (this.payoutaccounts.length > 0) {
          this.payoutaccounts[this.payoutaccounts.length - 1].isLastOption = true;
        }
        this.loading = false;


      }

    });


  }

  ngOnDestroy(): void {

  }
  clearAmount(): void {
    this.amount = "$";
  }

  updateBalance(): void {
    if (document.getElementById("transferbtn") != null) {
      this.validationMessage = '';
      let amt = this.amount.toString().replaceAll('$', '').replaceAll(',', '');

      document.getElementById("transferbtn").removeAttribute('disabled');
      if (amt != '')
        this.amount = formatCurrency(parseFloat(amt), 'en-us', '$');

      let inputsection = document.getElementById("inputsection");
      let underline = document.getElementsByClassName("underline")[0];

      let cashbalance = document.getElementById("cashbalance");
      let clearicon = document.getElementsByClassName("clear-icon")[0];


      if (parseFloat(this.cashBalance.toString()) < parseFloat(amt)) {
        inputsection.classList.add("error");
        clearicon.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        this.validationMessage = " Please type an amount less than your balance.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');

        return
      }
      else if (parseFloat(amt) <= 0 || amt == '') {
        inputsection.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        inputsection.classList.add("error");
        this.validationMessage = " Please enter an amount more than 0.00.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');
        return
      }
      else if (document.getElementById('instant')?.classList.contains('selected') && parseFloat(amt) <= 3) {
        inputsection.classList.add("error");
        underline.classList.add("error");
        cashbalance.classList.add("error");
        inputsection.classList.add("error");
        this.validationMessage = "For Instant transfer, Please enter an amount more than 3.00.";
        document.getElementById("transferbtn").setAttribute('disabled', 'true');
        return
      }

      else if (parseFloat(amt) <= parseFloat(this.cashBalance)) {
        inputsection.classList.remove("error");
        underline.classList.remove("error");
        cashbalance.classList.remove("error");
        clearicon.classList.remove("error");
        this.currentBalance = this.cashBalance;
        document.getElementById("transferbtn").removeAttribute('disabled');
        return
      }
      else {

      }
    }

  }

  goToReview() {
    this.holdbtn = true;
    if (document.getElementById('non-instant')?.classList.contains('selected')) {
      let config = new MatDialogConfig();
      config.data = "NonInstantConfirmation";
      let dialogRef = this.dialog.open(DynamicPopupComponent, config);
      dialogRef.afterClosed().subscribe(
        result => {
          this.holdbtn = false;
          if (result == 'close') {

            this.reviewTranfer();
            //this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
          }

        }
      );
    }
    else {
      this.reviewTranfer();
    }
  }
  reviewTranfer() {
    this.holdbtn = true;
    let transferType: any, charge: any, totaltransfer: any;
    let amt = this.amount.toString().replace(/^\$/, '').replaceAll(",", "");
    this.updateBalance();

    if (this.selectedtoken == "" || this.selectedtoken == undefined) {
      this.errorMessage = "Please select an account to transfer funds.";
      this.holdbtn = false;
      return
    }
    else {

      
      if (document.getElementById('instant')?.classList.contains('selected')) {
        transferType = 'RTP';
        let charge1 = (parseFloat(amt) * 0.015).toFixed(2);
        charge = formatCurrency(parseFloat(charge1), 'en-us', '$');
        let totaltransfer1 = (parseFloat(amt) - (parseFloat(amt) * 0.015)).toFixed(2);
        totaltransfer = formatCurrency((parseFloat)(totaltransfer1), 'en-us', '$');

      } else {
        transferType = 'NonRTP';
        charge = formatCurrency(0, 'en-us', '$');
        let totaltransfer1 = parseFloat(amt).toFixed(2).toString();
        totaltransfer = formatCurrency((parseFloat)(totaltransfer1), 'en-us', '$');
      }
      let objTransferCarrier: transferCarrier = new transferCarrier();
      let token: any = this.paymenttokens.filter((x: any) => x.payeeID == this.selectedtoken);



      let bankDetails: BankDetails = new BankDetails();
      if (token[0].achIndicator == 'T') {
        bankDetails.accountDetails = "Bank **** " + token[0].counterPartyAccountNumber;
        bankDetails.bankName = token[0].counterpartyPayOrg + " " + token[0].counterPartyBankAccountCategory + " Banking";
        bankDetails.imgSrc = token[0].cardImg;
      }
      else {
        bankDetails.accountDetails = token[0].counterPartyAccountNumber;
        bankDetails.bankName = "ZELLE";
        bankDetails.imgSrc = token[0].cardImg;
      }
      objTransferCarrier.token = this.selectedtoken;
      objTransferCarrier.accountType = transferType;
      objTransferCarrier.charges = charge;
      objTransferCarrier.totalTransferAmount = totaltransfer;
      objTransferCarrier.transferAmount = this.amount;
      objTransferCarrier.bankDetails = bankDetails;

      sessionStorage.setItem("transferCarrier", JSON.stringify(objTransferCarrier));
      this.route.navigate(['my-wallet', 'my-cash', 'review-summary']);
    }
  }

  // // If the autopay toggle is toggled on or off, check/uncheck all 3 autopay options
  // onToggleChange(event: any) {
  //   this.toggleText = this.autopayToggle ? 'On' : 'Off'; // autopayToggle variabe is automatically updated via ngModel
    
  //   // Update all 3 options to checked or unchecked based on the new autopayToggle value
  //   for (let i = 0; i < this.autopayOptions.length; i++) {
  //     this.autopayOptions[i].checked = this.autopayToggle;
  //   }
  // }

  // From Branch 1

// onToggleChange(event: any): void {
  
//   let transferType=document.getElementById('non-instant')?.classList.contains('selected') ? 'nonRTP' : 'RTP'
//   if (this.autoTransferToggle) {
//     if (!this.selectedtoken) {
//       this.autoTransferToggle = false;
//       this.errorMessage = 'Please select an account to set Auto Transfer.';
//           }
          
//         }
// else{
//     const dialogRef = this.dialog.open(TransferPopUpComponent, {
//       width: '832px',
//       data: {      
//         transferType: this.lastTransferType, // Pass transfer type
//         defaultToken: this.selectedtoken,   // Pass selected token
//       } // Pass any data to the modal if needed
//     });

//     dialogRef.afterClosed().subscribe((result) => {
//       if (result) {
//         // User confirmed "Yes"
//         const atransfer = {
//           defaultToken: this.selectedtoken,
//           isAutoTransfer: true,
//           transferType: transferType
//         };

//         sessionStorage.setItem('AutoTransfer', JSON.stringify(atransfer));
//         this.autotransferservice.setautotransfer(true,this.selectedtoken,atransfer.transferType).subscribe({next:(data:DWSetAutoTransferResponse)  => {
//         console.log(JSON.stringify(data));
//         }});
//         this.route.navigate(['my-wallet', 'my-cash', 'auto-transfer']);
//       } else {
//         // User canceled
//           this.autotransferservice.setautotransfer(false,this.selectedtoken,transferType).subscribe({next:(data:DWSetAutoTransferResponse)  => {
//             console.log(JSON.stringify(data));
//             }});
//         this.autoTransferToggle = false;
//       }
//     });
//   }}

// From Branch 2

  // onToggleChange(event: any) {
  //   this.toggleText = this.autoTransferToggle ? 'On' : 'Off'; 
  //   if(this.autoTransferToggle)
  //   {
  //     if (this.selectedtoken == "" || this.selectedtoken == undefined) {
  //       this.errorMessage = "Please select an account to set Auto Transfer.";
  //       this.autoTransferToggle=false;
  //       return
  //     }
  //     else{
  //     let atransfer= new AutoTransfer();
  //     atransfer.defaultToken=this.selectedtoken;
  //     atransfer.isAutoTransfer=true;
      
  //     if (document.getElementById('non-instant')?.classList.contains('selected'))
  //     {
  //       atransfer.transferType="nonRTP";
  //     }
  //     else
  //     {
  //       atransfer.transferType="RTP";
  //     }

      
  //     sessionStorage.setItem("AutoTransfer",JSON.stringify(atransfer));
  //     this.route.navigate(['my-wallet', 'my-cash', 'auto-transfer']);
  //   }
  //   }
   
  // }

  onToggleChange(event: any): void {
    if(this.payoutaccounts.length<1)
    {
    this.NoAccountsAT=true;
    this.ATValidationMsg="Please add a transfer account before enabling Auto-Transfer.";
    this.matSlideToggle.checked=false;
    this.autoTransferToggle=false;
    }else{
      this.NoAccountsAT=false;
    const transferType = document.getElementById('non-instant')?.classList.contains('selected')
      ? 'nonRTP'
      : 'RTP';
    if (this.autoTransferToggle) {
      if (this.selectedtoken == "" || this.selectedtoken == undefined || this.selectedtoken == "0") {
        this.NoAccountsAT=true;
    this.ATValidationMsg="Please select a transfer account before enabling Auto-Transfer.";
    this.matSlideToggle.checked=false;
    this.autoTransferToggle=false;
        return
}else{
      const dialogRef = this.dialog.open(TransferPopUpComponent, {
        width: '832px',
        data: {
          transferType,
          currentStatus: this.autoTransferToggle,
          defaultToken:this.selectedtoken
        }
      });
  
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.updateAutoTransfer(true, transferType);
        } else {
          this.autoTransferToggle = false; // Revert toggle
        }
      });
    } 
  }else {
      
      this.updateAutoTransfer(false, transferType);
    }
  }
}
  
  updateAutoTransfer(status: boolean, transferType: string): void {
    const request = {
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!),
      isDWAutoTransfer: status,
      transferType
    };
  
    this.autotransferservice.setautotransfer(status, this.selectedtoken, transferType).subscribe({
      next: (response) => {
        console.log(`Auto Transfer ${status ? 'enabled' : 'disabled'} successfully.`);
       let account= this.payoutaccounts.filter(x=>x.payeeID==this.selectedtoken);
        sessionStorage.setItem(
          'AutoTransfer',
          JSON.stringify({ isAutoTransfer: status, transferType,defaultToken:this.selectedtoken,accountnumber:
          account[0].counterPartyAccountNumber, bankname:account[0].counterpartyPayOrg })
        );
      },
      error: (error) => {
        console.error('Failed to update Auto Transfer:', error);
        this.autoTransferToggle = !status; // Revert toggle
      }
    });
  }
  

  selectedtile(isinstant: boolean): void {
    let instantdiv, noninstantdiv;
    this.NoAccountsAT=false;
    if(this.matSlideToggle.checked){
    this.matSlideToggle.checked=false;
    this.autoTransferToggle=false;}
    this.loading = true;
    if (isinstant) {
      this.showzelle = false;
      this.noninstantdiv?.classList.remove('selected');
      this.instantdiv?.classList.add('selected');


    }
    else {
      this.showzelle = true;
      this.instantdiv?.classList.remove('selected');
      this.noninstantdiv?.classList.add('selected');


    }
    this.getpayouttokens();
    this.updateBalance();
  }

  gotoPayMyBill() {

    this.route.navigate(['/my-wallet/all-rewards/redeem'], { relativeTo: this.routeActivate });
  }
  addPaymentMethod(): void {
    this.holdbtn = true;

    if (sessionStorage.length > 0) {
      this.WalletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    }


    this.dwdpatsession = new DWDPATSessionModel()
    this.dwdpatsession.action = 'addpayoutpayment';
    this.dwdpatsession.callBackURL = `${ApiEndpoints.startWalletSessionCallBack}/my-wallet/my-cash/transfer-balance`;
    this.dwdpatsession.country = 'US';
    this.dwdpatsession.createdByUserId = 'appdw';
    this.dwdpatsession.locale = 'en';
    this.dwdpatsession.navigatingSystem = 'dw';
    this.dwdpatsession.paymentDirection = 'payout';
    this.dwdpatsession.walletID = this.WalletID;
    this.dwdpatsession.cashbalance = this.currentBalance;
    this.dwdpatsession.rewardsBalance = this.rewardsBalance;

    this.sessionservice.createdwdpatwalletsession(this.dwdpatsession);
    this.holdbtn = false;
  }

  blurRest() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    let dialogRef = this.dialog.open(TransferFlyoutComponent, config);
  }

  enableBtn() {

    this.errorMessage = '';
    this.matSlideToggle.checked=false;
    this.autoTransferToggle=false;
    this.NoAccountsAT=false;
  }

  closeBanner(): void {
    this.showBanner = false;
  }


}
