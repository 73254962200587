import { Component, OnInit, ViewChild } from '@angular/core';
import  jsonData from '../../../../assets/json/transactions.json';
import { ButtonClickService } from 'src/app/shared/services/button-click.service';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { TransactionHistoryService } from 'src/app/shared/services/api/transaction-history.service';
import { CashEarn } from 'src/app/shared/models/transaction-history.interface';
import { CashCommon } from 'src/app/shared/models/CashCommon.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { transition } from '@angular/animations';


@Component({
  selector: 'app-all-cash',
  templateUrl: './all-cash.component.html',
  styleUrls: ['./all-cash.component.scss']
})
export class AllCashComponent implements OnInit {

 // displayedColumns: string[] = ['select', 'earnDate', 'cashEarned', 'productName', 'earnCategory', 'earnIdentifier'];
  dataSource = new MatTableDataSource<CashCommon>([]);
  displayedColumns: string[] = [];
  autoPay: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selection = new SelectionModel<CashCommon>(true, []);
  searchValue: string = '';

  
  rewardType: string = 'cashEarn';
  walletID: string = '';
  walletRole: string = ''  

  cashEligible = false;
  rewardEligible = false;

  cashEarnedList: any[] = [];
  earnCategoryList: any[] = [];
  detailsList: any [] = [];
  dateList: any [] = [];
  referenceNumberList: any[] = [];
  dataLoaded = false;



  constructor(
    public buttonClick:ButtonClickService,
    private walletSummaryService: WalletSummaryService,
    private transactionHistoryService: TransactionHistoryService){}

  transactions = jsonData;
  walletSummary: WalletSummary | null = null;
  
  selectAll(event: Event) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    
    this.transactions.forEach(transaction => {
      transaction.selected = isChecked;
    });
  }

  goToRedeem(){
    this.buttonClick.goToRedeem();
  }

  goToTransferBalance(){
    this.buttonClick.goToTransferBalance();
  }

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
        this.walletID = summary.wallet.walletID;
        this.walletRole = 'admin'; // Replace 'admin' with the actual logic to determine walletRole
        this.cashEligible = summary?.wallet?.cashEligible === "True";
        this.rewardEligible = summary?.wallet?.rewardEligible === "True";
        
        // Ensure isDWAutoPay is properly converted to number
        const isDWAutoPayValue = Number(summary.wallet.isDWAutoPay);
        this.autoPay = isDWAutoPayValue;

        this.walletSummary = summary;

        this.dataLoaded = true;
        this.fetchCash();
      }
 

    });

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dateString = new Date(data.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
      return dateString.toLowerCase().includes(filter.toLowerCase());
    };

    // this.dataSource.sortingDataAccessor = (item, property) => {
    //   switch (property) {
    //     case 'earnDate': return new Date(item.date);
    //     case 'spendDate': return new Date(item.date);
    //     default: return item[property];
    //   }
    // };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'earnDate': 
        case 'spendDate':
          return new Date(item[property]);
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


    // Set default sort to earnDate or spendDate column
    this.sort.sort({ id: 'earnDate', start: 'desc', disableClear: true });
  //  this.sort.sort({ id: 'spendDate', start: 'desc', disableClear: true });
  }

  fetchCash(): void {
    this.transactionHistoryService.fetchTransactionHistory(this.walletID, this.walletRole, 'cash').subscribe(
      (data) => {
        if (this.rewardType === 'cashEarn') {
          // Sort cashEarn data by earnDate in descending order (latest first)
          const sortedData = this.transformData(data.walletTransactionsList.cash.cashEarn, true)
            .sort((a, b) => new Date(b.earnDate).getTime() - new Date(a.earnDate).getTime());
  
          this.dataSource = new MatTableDataSource(sortedData);
          this.displayedColumns = ['select', 'earnDate', 'cashEarned', 'earnCategory', 'earnIdentifierDescription', 'earnIdentifier','trackingNumber'];
  
        } else if (this.rewardType === 'cashSpend') {
          // Sort cashSpend data by spendDate in descending order (latest first)
          const sortedData = this.transformData(data.walletTransactionsList.cash.cashSpend, false)
            .sort((a, b) => new Date(b.spendDate).getTime() - new Date(a.spendDate).getTime());
  
          this.dataSource = new MatTableDataSource(sortedData);
          this.displayedColumns = ['select', 'spendDate', 'spendAmount', 'spendCategory', 'spendIdentifierDescription', 'spendAccountAlias', 'spendAccountBankName'];
        }
  
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
  
      },
      (error) => {
        console.error('Error fetching cash transactions:', error);
      }
    );
  }
  

  transformData(data: any[], isEarn: boolean): any[] {
    // Transform the data as needed
    return data;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearch(searchValue: string) {
    const formattedSearchValue = searchValue.trim().toLowerCase();
    this.dataSource.filter = formattedSearchValue;
   // this.dataSource.filter = searchValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onRewardTypeChange(rewardType: string): void {
    const walletID = sessionStorage.getItem('WalletID');
    const walletRole = sessionStorage.getItem('WalletRole');

    this.rewardType = rewardType;
    this.displayedColumns = this.getDisplayedColumns();
   
    if (walletID && walletRole) {
      this.fetchCash();
    } else {
      console.error('Wallet ID or Wallet Role is missing');
    }
    console.log('Reward Type changed to:', rewardType);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: CashCommon): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.date + row.amount}`;
  }

  getDisplayedColumns(): string[] {
    if (this.rewardType === 'cashEarn') {
      return ['select', 'earnDate', 'cashEarned', 'earnCategory', 'earnIdentifier', 'earnIdentifierDescription'];
    } else if (this.rewardType === 'cashSpend') {
      return ['select', 'spendDate', 'spendAmount', 'spendCategory', 'spendIdentifierDescription'];
    } else {
      // Default or error case
      return [];
    }
  }

}
